import React from "react";
import {
  FormControl,
  MenuItem,
  InputLabel,
  BottomNavigation,
  BottomNavigationAction,
  TextField,
  Grid,
  Container,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Fab,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import { FRONTEND_API } from "./urls";
import { DialogContentText, Alert } from "@mui/material";

import UpgradeOutlinedIcon from "@mui/icons-material/UpgradeOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import "./css/invoices.css";
import { ButtonContainer } from "./styles/style";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AssingExpert from "./icons/edit.png";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  DateCalendar,
  DatePicker,
  MobileTimePicker,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { format } from "date-fns";
import UpdateSharpIcon from "@mui/icons-material/UpdateSharp";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import calendarIcon from "./img/calendar.png";

import {
  StyledTableCell,
  StyledTableRow,
  StyledTableSortLabel,
} from "./styles/TableStyles";
import TablePagination from "@mui/material/TablePagination";
import AddIcon from "@mui/icons-material/Add";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { currencies } from "./constants/constants";
import OrderDetails from "./OrderDetails";
import { method, orderBy } from "lodash";
import deleteModalIcon from "./icons/deleteIcon.png";
import editIcon from "./icons/order.png";
import CommonModal from "./common/CommonModal";
import EditTaskIcon from "./icons/editTask.png";
import MessageComponent from "./MessageComponent";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DPRConsole from "./DPRConsole";
import { LineAxisOutlined, Token } from "@mui/icons-material";
import { truncate } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import AddCommentIcon from "@mui/icons-material/AddComment";
import axios from "axios";
import TeamLeadOperationTable from "./TeamLeadOperationTable";
import { useLocation } from "react-router-dom";
const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  color: #ffff;
  &.Mui-selected {
    background-color: #f34c19;
    font-size: 24px;
    color: #ffff;
  }
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Replace the shadow values with your desired elevation */
  background-color: #343f71;
  font-size: 20px !important;
  margin-right: 13px;
  border-radius: 5px;
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function AssignTaskConsole() {
  const [orders, setOrders] = useState([]);
  const [ordersId, setOrdersId] = useState([]);
  const [ordersIdEdit, setOrdersIdEdit] = useState([]);
  const [Vendor_budget, setVendor_budget] = useState("");

  const [userType, setUserType] = useState("");

  const [bottomNavSub, setBottomNavSub] = useState("new order");
  const [currentStatus, setCurrentStatus] = useState("new order");

  const [prevStatus, setPrevStatus] = useState("");

  const token = localStorage.getItem("token");
  const roles = localStorage.getItem("roles");
  const userId = localStorage.getItem("userId");

  const today = dayjs();

  const [Status, setStatus] = useState("");

  const [openTask, setOpenTask] = React.useState(false);
  const [openExpertsDialog, setExpertsDialog] = React.useState(false);
  const [openDCExpertsDialog, setOpenDCExpertsDialog] = React.useState(false);

  const [openEdit, setOpenEdit] = React.useState(false);

  const [orderStatusValid, setOrderStatusValid] = useState(null);
  const [expertValid, setExpertValid] = useState(null);
  const [otmValid, setOtmValid] = useState(null);
  const [vendorBudgetValid, setVendorBudgetValid] = useState(null);

  const [expertPriceValid, setExpertPriceValid] = useState(null);
  const [wordCountValid, setWordCountValid] = useState(null);

  const [expertPrice, setExpertPrice] = useState("");
  const [wordCount, setWordCount] = useState("");
  const [qc_expert, setQC_Expert] = useState("");

  const [subject, setSubject] = useState("");
  const [endDate, setEndDate] = useState(dayjs().startOf("day"));

  const [dialogOpen, setDialogOpen] = useState(false);

  const validateStatus = (value) => value != "";
  const validateExpert = (value) => value != "";
  const validateOtm = (value) => value != "";
  const validateVendorBudget = (value) => /^\d+$/.test(value);

  const validateExpertPrice = (value) => !isNaN(value) && value.length < 5;
  const validateWordCount = (value) => !isNaN(value);

  const [expert, setexpert] = useState([]);
  const [Qc_Expert_name, setQc_Expert_name] = useState("");
  const [Comments, setComments] = useState("");

  const [Qc_Expert, setQc_Expert] = useState("");

  const [otmMember, setOtmMember] = useState([]);

  const [otmUser, setOtmUser] = React.useState("");

  const [expertCurrency, setExpertCurrency] = React.useState("");
  const [budgetCurrency, setBudgetCurrency] = React.useState("");

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const buttonRef = useRef(null);
  const orderDealineButtonRef = useRef(null);
  const navigate = useNavigate();

  const [Description, setDescription] = useState("");

  const [severity, setSeverity] = useState(null);
  const [alertContent, setAlertContent] = useState("");

  const [invoiceDate, setInvoiceDate] = useState(today);
  const [openInvoiceDatePicker, setOpenInvoiceDatePicker] = useState(false);
  const invoiceButtonRef = useRef(null);
  const [openEditOrderDeadline, setOpenEditOrderDeadline] = useState(false);
  const [openEditOrderDeadlinePicker, setOpenEditOrderDeadlinePicker] =
    useState(false);
  const [openEditExpertDeadlinePicker, setEditExpertDeadlinePicker] =
    useState(false);
  const [meetingDeadlinePicker, setMeetingDeadlinePicker] = useState(false);
  const [timeDeadlinePicker, setTimeDeadlinePicker] = useState(false);
  const [selectedExpertId, setSelectedExpertId] = useState("");
  const [orderDeadlineDate, setOrderDeadlineDate] = useState(
    dayjs().startOf("day")
  );
  const [expertDeadlineDate, setExpertDeadlineDate] = useState(
    dayjs().startOf("day")
  );
  const [openEditSubject, setOpenEditSubject] = useState(false);
  const [editSubject, setEditSubject] = useState("");
  const [SelectedOrderId, setSelectedOrderId] = useState("");

  const [selectedDate, setSelectedDate] = useState("");
  const [openEditExpertDeadline, setOpenEditExpertDeadline] = useState(false);
  const [expertDeadlineSort, setExpertDeadlineSort] = useState("asc");

  const [orderByColumn, setOrderByColumn] = useState("deadline");
  const [sortType, setSortType] = useState("expertDeadline");
  const [orderDeadlineColumn, setOrderDeadlineColumn] = useState("deadline");

  const [orderDeadlineSort, setOrderDeadlineSort] = useState("asc");
  const [commentForOperation, setCommentForOperation] = useState("");
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isTlCommentModalOpen, setIsTlCommentModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedExpertType, setSelectedExpertType] = useState("");
  const isEditAllowed = roles == "admin";
  const [isAdminAssingTab, setIsAdminAssignTab] = useState(false);
  const [isUrgentDeadlineModalOpen, setIsUrgentDeadlineModalOpen] =
    useState(false);
  const [isHighPriorityModalOpen, setIsHighPriorityModalOpen] = useState(false);
  const [isAddMeetingScheduleModalOpen, setIsAddMeetingScheduleModalOpen] =
    useState(false);

  const [meetingData, setmeetingData] = useState({
    deadline: null,
    meetingTime: null,
    meetingComment: "",
  });

  const [priorityData, setPriorityData] = useState({
    priorityDeadline: null,
    priorityComment: "",
  });

  const [urgentData, setUrgentData] = useState({
    urgentDate: null,
    urgentComment: "",
  });

  const [highPriorityDeadline, setHighPriorityDeadline] = useState("");
  const [highPriorityComment, setHighPriorityComment] = useState("");
  const [urgentDeadline, setUrgentDeadline] = useState("");
  const [urgentComment, setUrgentComment] = useState("");
  const [teamLeadOperationData, setTeamLeadOperationData] = useState([]);
  const [currentOperationTab, setCurrentOperationTab] = useState("");
  const resetFormFields = () => {
    setStatus("");
    setQc_Expert_name("");
    setComments("");
    setOtmUser("");
    // Reset other form fields if needed
  };
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [filteredRowsPerPageOptions, setFilteredRowsPerPageOptions] = useState([
    10, 25, 100,
  ]);
  const [page, setPage] = useState(1);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 25, 100]);
  const [endPageNumber, setEndPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [tableValues, setTableValues] = useState([]);
  const query = useQuery();
  const dashboardTab = query.get("tab");

  console.log("dashboardtab is here", dashboardTab);
  const resetValidationFields = () => {
    setOrderStatusValid(null);
    setExpertValid(null);
    setOtmValid(null);
  };

  useEffect(() => {
    if (dashboardTab === "assigned") {
      setBottomNavSub(dashboardTab);
      fetchDataForSubject(dashboardTab);
    } else if (roles === "admin") {
      setBottomNavSub("new order");
      setCurrentStatus("new order");
    } else if (roles === "lead") {
      setBottomNavSub("AssignedByAdmin");
      setCurrentStatus("assigned");
    } else {
      setBottomNavSub("assigned");
      setCurrentStatus("assigned");
    }
  }, [roles]);
  useEffect(() => {
    setSortType("orderDeadline");
  }, []);
  const fetchDataForEdit = () => {
    var formdata = new FormData();

    formdata.append("type", "otm");

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(FRONTEND_API + "getUsers", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // do something with data
        console.log(data);
        setOtmMember(data);
      })
      .catch((rejected) => {
        console.log(rejected);
      });
  };

  const handleVendorBudgetChange = (event) => {
    const { name, value } = event.target;
    setVendor_budget(value);
    setVendorBudgetValid(validateVendorBudget(value));
  };

  const handleChangeQc = (event) => {
    const value = event.target.value;
    setQc_Expert_name(value);
    setExpertValid(validateExpert(value));
    console.log(event.target.value);
  };
  const handleChangecomment = (event) => {
    const value = event.target.value;
    setComments(value);
    setExpertValid(validateExpert(value));
    console.log(event.target.value);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    console.log("handleChange", value);
    setQc_Expert(value);
    setExpertValid(validateExpert(value));
    console.log("handleChangeQc1", event.target.value);
  };

  const onChangeExpertPrice = (event) => {
    const value = event.target.value;
    setExpertPrice(value);
    setExpertPriceValid(validateExpertPrice(value));
    console.log(event.target.value);
  };

  // const onChangeWordCount = (event) => {
  //     const value = event.target.value;
  //     setWordCount(value);
  //     setWordCountValid(validateWordCount(value));
  //     console.log(event.target.value);
  // };

  const handleChangeStatus = (event) => {
    const value = event.target.value;
    setStatus(value);
    setOrderStatusValid(validateStatus(value));
    console.log(event.target.value);
  };

  const handleStatusUpdate = (id, prevStat) => {
    setOpenTask(true);
    setOrdersId(id);
    //setPrevStatus(prevStat);
  };

  const handleQCStatusUpdate = (id, prevStat) => {
    setOpenTask(true);
    setOrdersId(id);
    //setPrevStatus(prevStat);
  };

  const handleExpertUpdate = (orderData, prevStat) => {
    setExpertsDialog(true);

    console.log("orderData is here", orderData);
    setOrdersId(orderData.id);
    // if (currentStatus === "assigned") {
    //   setUserType(orderData.expert_type);
    //   setSelectedOrderId(orderData.id);
    //   fetchExperts(orderData.expert_type);
    //   setQc_Expert_name(orderData.expertId);
    //   setComments(orderData.comments);

    //   setTasks(
    //     orderData.deadlines_with_word_count.map((item, index) => {
    //       return {
    //         id: item.id,
    //         invoiceDate: dayjs(item.expert_deadline),
    //         wordCount: item?.expert_word_count,
    //       };
    //     })
    //   );
    // }
    //setPrevStatus(prevStat);
  };

  const handleDCExpertUpdate = (id, prevStat) => {
    console.log("handleDCExpertUpdate", id);
    setOpenDCExpertsDialog(true);
    setOrdersId(id);
    //setPrevStatus(prevStat);
  };

  const handleModalEdit = (id) => {
    fetchDataForEdit();
    setOrdersIdEdit(id);
    const filteredOrders = orders.filter((order) => order.id == id);
    setWordCount(filteredOrders[0].word_count);
    setExpertPrice(filteredOrders[0].expert_price);
    setVendor_budget(filteredOrders[0].budget);
    setSubject(filteredOrders[0].subject);
    const dateString = filteredOrders[0].order_end_date; // Assuming it's a string
    const dateObject = dayjs(dateString);
    setEndDate(dateObject);
    setOpenEdit(true);
    setBudgetCurrency(filteredOrders[0].currency);
    setExpertCurrency(filteredOrders[0].expert_currency);
    setDescription(filteredOrders[0].description);
  };

  const [isOrderDetailsOpen, setOrderDetailsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const openOrderDetails = (id) => {
    const clickedOrder = orders.find((order) => order.id === id);
    setSelectedOrder(clickedOrder);
    setOrderDetailsOpen(true);
  };

  const closeOrderDetails = () => {
    setOrderDetailsOpen(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    //resetFormFields();
  };

  const handleClose = () => {
    setOpenTask(false);
    //resetFormFields();
    setUserType("");
  };

  const closeExpertDialog = () => {
    setExpertsDialog(false);
    setexpert([""]);
  };

  const closeQCExpertDialog = () => {
    setOpenDCExpertsDialog(false);
    setexpert([""]);
  };

  const [tasks, setTasks] = useState([
    { id: "", invoiceDate: today, wordCount: "" },
  ]);
  const [QCtasks, setQCTasks] = useState([{ qc_expert: "" }]);

  const handleInvoiceButtonClick = (index) => {
    console.log("index from the function", index);
    setSelectedIndex(index);
    setOpenInvoiceDatePicker(!openInvoiceDatePicker);
  };
  const onChangeQcExpert = (event, index) => {
    const { value } = event.target;
    // Update the word count for the specific task at the given index
    const updatedQCTasks = QCtasks.map((qctask, i) =>
      i === index ? { ...qctask, qc_expert: value } : qc_expert
    );
    // Update tasks state with the modified tasks array
    setTasks(updatedQCTasks);
  };

  const handleInvoiceDateChange = (date) => {
    // console.log("handle invoice date change", date, "id is", id);
    // console.log("id from parent", id);
    console.log("tasks", tasks);
    const updatedTasks = tasks.map((task, i) =>
      i === selectedIndex ? { ...task, invoiceDate: date } : task
    );
    setTasks(updatedTasks);
    setOpenInvoiceDatePicker(false);
  };

  const onChangeWordCount = (event, index) => {
    console.log("indside wordcount index", index);
    const { value } = event.target;
    // Update the word count for the specific task at the given index
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, wordCount: value } : task
    );
    // Update tasks state with the modified tasks array
    setTasks(updatedTasks);
  };

  const addNewTask = () => {
    setTasks([...tasks, { invoiceDate: today, wordCount: "" }]);
  };

  const removeTask = (index) => {
    const updatedTasks = tasks.filter((task, i) => i !== index);
    setTasks(updatedTasks);
  };

  const expertTaskUpdate = async () => {
    const taskForExpert = tasks.map((task) => {
      return {
        id: task.id ? task.id : "",
        deadline: dayjs(task.invoiceDate).format("YYYY-MM-DD"),
        word_count: task.wordCount,
      };
    });
    console.log("task for Expert updae", expertTaskUpdate);
    let responseStatus = 0;
    var requestOptions = {
      method: "PUT",
      body: JSON.stringify({
        comments: Comments,
        deadline: taskForExpert,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      `${FRONTEND_API}/update_order/${SelectedOrderId}/${Qc_Expert_name}`,
      requestOptions
    );
    const data = await response.json();
    if (response.status < 299) {
      console.log("response status", responseStatus);
      toast.success(data.message ? data.message : "Order updated successfully");
      setExpertsDialog(false);
      if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
        getSearchValues(searchQuery, bottomNavSub);
      } else if (bottomNavSub === "AssignedByAdmin") {
        fetchDataForSubject(currentStatus, bottomNavSub);
      } else if (searchQuery !== "") {
        getSearchValues(searchQuery);
      } else {
        fetchDataForSubject(currentStatus);
      }
    } else if (response.status < 500) {
      toast.error(data.message ? data.message : "Provide data properly");
    } else {
      toast.error("internal server error");
    }
  };

  const handleUpdateExpert = () => {
    // if (currentStatus !== "new gorder") {
    //   expertTaskUpdate();
    // } else {
    updateStatusData("expert");
    // }
    setUserType("");
    setTasks([{ invoiceDate: today, wordCount: "" }]);
    setComments("");

    setQc_Expert_name("");
  };

  const handleUpdateQCExpert = () => {
    updateStatusData("qc_expert");
  };
  const handleUpdate = () => {
    console.log(orderStatusValid, expertValid);
    if (Status == "pass") {
      if (orderStatusValid) {
        updateStatusData("status");
      } else {
        setDialogOpen(true);
      }
    } else if (Status == "assigned") {
      if (orderStatusValid) {
        updateStatusData("status");
      } else {
        setDialogOpen(true);
      }
    } else if (Status == "qc" || Status == "fail" || Status == "rework") {
      if (orderStatusValid) {
        updateStatusData("status");
      } else {
        setDialogOpen(true);
      }
    } else {
      setDialogOpen(true);
    }
    setUserType("");
  };

  const handleQCUpdate = () => {
    console.log("handleQCUpdate", orderStatusValid, expertValid);
    if (Status == "pass") {
      if (orderStatusValid) {
        updateStatusData("status");
      } else {
        setDialogOpen(true);
      }
    } else if (Status == "assigned") {
      if (orderStatusValid) {
        updateStatusData("status");
      } else {
        setDialogOpen(true);
      }
    } else if (Status == "qc" || Status == "fail" || Status == "rework") {
      if (orderStatusValid) {
        updateStatusData("status");
      } else {
        setDialogOpen(true);
      }
    } else {
      setDialogOpen(true);
    }
    setUserType("");
  };

  const updateStatusData = (condition) => {
    var formdata = new FormData();

    formdata.append("updateCondition", condition);
    formdata.append("current_expert_id", userId);
    if (condition == "status") {
      //Only status is updated
      formdata.append("status", Status);
    } else {
      if (currentStatus == "new order") {
        formdata.append("roles", roles);
        formdata.append("status", "assigned");
        formdata.append("data", JSON.stringify({ task: tasks }));
        formdata.append("expert_id", Qc_Expert_name);
        formdata.append("comments", Comments);
      } else if (
        currentStatus == "assigned" ||
        currentStatus == "qc" ||
        currentStatus === "rework" ||
        currentStatus === "pass"
      ) {
        formdata.append("roles", roles);
        formdata.append("data", JSON.stringify({ task: tasks }));
        formdata.append("qc_expert_id", Qc_Expert);
        formdata.append("expert_id", Qc_Expert_name);
        formdata.append("comments", Comments);
      }
      console.log("type", userType);
      // if (roles == 'lead') {
      //     formdata.append('roles', 'lead');
      // }
      // if (currentStatus == 'new order') {
      //     formdata.append('status', 'assigned');
      //     formdata.append('expert_date', invoiceDate.format('YYYY-MM-DD'));
      // }
      // formdata.append('expert_id', Qc_Expert_name);
      // formdata.append('expert_date', invoiceDate.format('YYYY-MM-DD'));
    }

    console.log("STATus", currentStatus);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    fetch(FRONTEND_API + "updateStatusWithId/".concat(ordersId), requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
      })
      .then((data) => {
        //do something with data
        resetFormFields();
        resetValidationFields();
        if (bottomNavSub === "AssignedByAdmin" && roles === "lead") {
          setBottomNavSub("assigned");
        }
        if (
          bottomNavSub === "AssignedByAdmin" &&
          searchQuery !== "" &&
          roles === "lead"
        ) {
          setBottomNavSub("assigned");
          getSearchValues(searchQuery);
        } else if (bottomNavSub === "AssignedByAdmin" && roles === "lead") {
          setBottomNavSub("assigned");
          fetchDataForSubject(currentStatus);
        } else if (searchQuery !== "") {
          getSearchValues(searchQuery);
        } else {
          fetchDataForSubject(currentStatus);
        }
        if (condition == "expert") {
          setExpertsDialog(false);
        } else if (condition == "qc_expert") {
          console.log("qc", condition);
          setOpenDCExpertsDialog(false);
        } else {
          setOpenTask(false);
        }
      })
      .catch((rejected) => {
        console.log(rejected);
      });
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  console.log("current working page", page);
  const handleOrderUpdate = () => {
    console.log("In edit");
    if (wordCountValid || endDate) {
      console.log("In edit cond valid");
      var formdata = new FormData();
      formdata.append("word_count", wordCount);
      formdata.append("expert_price", expertPrice);
      formdata.append("budget", Vendor_budget);
      formdata.append("subject", subject);
      formdata.append("deadline", endDate.format("YYYY-MM-DD"));
      formdata.append("currency", budgetCurrency);
      formdata.append("expert_currency", expertCurrency);
      formdata.append("comment", Description);

      var requestOptions = {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      fetch(
        FRONTEND_API + "updateOrderWithId/".concat(ordersIdEdit),
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          // do something with data
          console.log("budget DATA", data);
          fetchDataForSubject(currentStatus);
          setOpenEdit(false);
        })
        .catch((rejected) => {
          console.log(rejected);
        });
    }
  };

  const handleQCOrderUpdate = () => {
    console.log("In edit qchhh");
    if (wordCountValid || endDate) {
      console.log("In edit cond valid");
      var formdata = new FormData();
      formdata.append("word_count", wordCount);
      formdata.append("expert_price", expertPrice);
      formdata.append("budget", Vendor_budget);
      formdata.append("subject", subject);
      formdata.append("deadline", endDate.format("YYYY-MM-DD"));
      formdata.append("currency", budgetCurrency);
      formdata.append("expert_currency", expertCurrency);
      formdata.append("comment", Description);

      var requestOptions = {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      fetch(
        FRONTEND_API + "updateOrderWithId/".concat(ordersIdEdit),
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          // do something with data
          console.log("budget DATA", data);
          fetchDataForSubject(currentStatus);
          setOpenEdit(false);
        })
        .catch((rejected) => {
          console.log(rejected);
        });
    }
  };

  const handleUserTypeChange = (event) => {
    //setSelectedRadio(event.target.value);
    console.log("selected user type", event.target.value);
    console.log(event.target.value);
    setUserType(event.target.value);
    //fetchInitial(event.target.value);
    fetchExperts(event.target.value);
  };

  const handleDate = (expertDate) => {
    var formattedDate = null;
    if (expertDate != null) {
      const dateObject = new Date(expertDate);
      formattedDate = dateObject.toDateString();
    }
    return formattedDate;
  };

  const handleDates = (expertDates) => {
    if (!expertDates || expertDates.length === 0) {
      return null;
    }

    // Ensure expertDates is an array
    if (!Array.isArray(expertDates)) {
      expertDates = [expertDates];
    }

    const formattedDates = expertDates.map((dateString) => {
      const dateObject = new Date(dateString);
      const day = String(dateObject.getDate()).padStart(2, "0");
      const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-based month index
      const year = dateObject.getFullYear();

      // Format date as "dd/mm/yyyy"
      return `${day}/${month}/${year}`;
    });

    return formattedDates.join(", ");
  };

  const dateToday = new Date();
  const yesterday = new Date(dateToday);
  yesterday.setDate(dateToday.getDate() - 1);
  const dayBeforeYesterday = new Date(dateToday);
  dayBeforeYesterday.setDate(dateToday.getDate() - 2);

  const formattedToday = handleDates([dateToday]);
  const formattedYesterday = handleDates([yesterday]);
  const formattedDayBeforeYesterday = handleDates([dayBeforeYesterday]);

  const handleBudget = (budget, currency) => {
    if (currency == "GBP") {
      return "£" + budget;
    } else if (currency == "USD") {
      return "$" + budget;
    } else {
      return "₹" + budget;
    }
  };

  const fetchExperts = (type) => {
    var formdata = new FormData();
    formdata.append("user_type", type);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(FRONTEND_API + "getExpertToAssign", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // do something with data
        console.log(data);
        setexpert(data);
      })
      .catch((rejected) => {
        console.log(rejected);
      });
  };

  // code for assign to QC Expert

  const fetchDataForSubject = (
    status,
    tabType,
    newRowsPerPage,
    incrementPage
  ) => {
    //console.log(subject);
    console.log("status from fetchDataForSubjectFunction", status);
    console.log("status inside fetch", status);
    var formdata = new FormData();
    formdata.append("status", status); //status
    console.log(" new rows per page inside fetch", newRowsPerPage);
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(
      `${FRONTEND_API}${
        tabType === "AssignedByAdmin"
          ? "adminassignedby"
          : "getOrdersBySubjectAndStatus"
      }?page=${incrementPage ? incrementPage : page}&per_page=${
        newRowsPerPage && newRowsPerPage >= 10
          ? newRowsPerPage
          : rowsPerPage && rowsPerPage >= 10
          ? rowsPerPage
          : rowsPerPage
      }`,
      requestOptions
    )
      .then((res) => res.json())
      .then((rawData) => {
        console.log("raw data", rawData);
        setTotalDataCount(rawData.pagination.total_orders);
        if (roles == "admin") {
          const sortedOrdersByDate = rawData?.orders?.sort((a, b) => {
            return (
              new Date(
                a.expert_type === "expert"
                  ? a.assigned_expert_deadline
                  : a.order_end_date
              ) -
              new Date(
                a.expert_type === "expert"
                  ? b.assigned_expert_deadline
                  : b.order_end_date
              )
            );
          });
          console.log("sorderedOrdersByDate", sortedOrdersByDate);
          setOrders(sortedOrdersByDate);
        } else if (
          roles == "expert" ||
          roles == "lead" ||
          roles == "qc_expert"
        ) {
          console.log(roles);
          console.log("rawData is here", rawData);
          let filteredOrders = rawData.orders.filter(
            (order) =>
              order.expertId == userId ||
              order.assigned_expert_id == userId ||
              order.qc_expert_id == userId
          );
          // if (
          //   roles == "lead" &&
          //   currentStatus === "assigned" &&
          //   tabType === ""
          // ) {
          //   filteredOrders = filteredOrders.filter(
          //     (order) => order.is_admin_assigned_by === null
          //   );
          // }
          console.log("filteredOrders inside lead", filteredOrders);
          // if (roles === "lead" && tabType === "AssignedByAdmin") {
          //   console.log("inside filter admin");
          //   filteredOrders = filteredOrders.filter(
          //     (order) => order.is_admin_assigned_by === 0
          //   );
          // }
          let teamLeadOperationFilter = [];
          if (roles === "lead" && tabType === "UrgentDeadline") {
            teamLeadOperationFilter = rawData.orders
              .filter((order) => order.urgent_deadline.urgent_deadline !== null)
              .map((order) => {
                return {
                  id: order.id,
                  urgent_deadline: order.urgent_deadline.urgent_deadline,
                  urgent_comment: order.urgent_deadline.urgent_deadline_comment,
                };
              });
          } else if (roles === "lead" && tabType === "HighPriority") {
            teamLeadOperationFilter = rawData.orders
              .filter(
                (order) =>
                  order.high_priority_deadline.high_priority_deadline !== null
              )
              .map((order) => {
                return {
                  id: order.id,
                  high_priority_deadline:
                    order.high_priority_deadline.high_priority_deadline,
                  high_comment:
                    order.high_priority_deadline.high_priority_comment,
                };
              });
          } else if (roles === "lead" && tabType === "meetingSchedule") {
            teamLeadOperationFilter = rawData.orders
              .filter(
                (order) =>
                  order.meeting_sche_deadline.meeting_sche_deadline !== null
              )
              .map((order) => {
                return {
                  id: order.id,
                  meeting_sche_deadline:
                    order.meeting_sche_deadline.meeting_sche_deadline,
                  meeting_sche_comment:
                    order.meeting_sche_deadline.meeting_sche_comment,
                  meeting_sche_time: dayjs(
                    order.meeting_sche_deadline.meeting_sche_time
                  ).format("HH:mm:ss"),
                };
              });
          }
          setTeamLeadOperationData(teamLeadOperationFilter);
          console.log("inside admin else");
          console.log("filter order outside", filteredOrders);
          setOrders(rawData.orders);
        } else {
          setOrders(rawData.orders);
        }
      })

      .catch((rejected) => {
        console.log(rejected);
      });
  };

  const handleButtonClick = () => {
    if (openDatePicker) {
      setOpenDatePicker(false);
    } else {
      setOpenDatePicker(true);
    }
  };

  const handleEndDateChange = (date) => {
    //const { name, value } = event.target;
    // console.log(date);
    setEndDate(date);
    setOpenDatePicker(false);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const [openWarn, setOpenWarn] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleClickOpenWarn = (id) => {
    setOpenWarn(true);
    setDeleteId(id);
  };

  const handleCloseWarn = () => {
    setOpenWarn(false);
  };

  const handleDeleteOrder = () => {
    var requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    fetch(FRONTEND_API + "deleteOrder/".concat(deleteId), requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
          getSearchValues(searchQuery, bottomNavSub);
        } else if (bottomNavSub === "AssignedByAdmin") {
          fetchDataForSubject(currentStatus, bottomNavSub);
        } else if (searchQuery !== "") {
          getSearchValues(searchQuery);
        } else {
          fetchDataForSubject(currentStatus);
        }
        setOpenWarn(false);
      })
      .catch((rejected) => {
        console.log(rejected);
      });
  };

  const handleFabClick = () => {
    // Navigate to the /addtask route
    navigate("/addtask");
  };
  const handleRequestSort = (property) => {
    // console.log("handle request sort working with orders", orders);
    // console.log("sort request", property);
    // console.log("order sorting from function", expertDeadlineSort);
    // console.log("order by column", orderByColumn);
    // console.log("order sorting", expertDeadlineSort);
    const newOrder =
      orderByColumn === property && expertDeadlineSort === "asc"
        ? "desc"
        : "asc";

    setExpertDeadlineSort(newOrder);
    setOrderByColumn(property);
  };

  const handleRequestOrderSort = (property) => {
    console.log("handle request order sort working");
    const newOrder =
      orderDeadlineColumn === property && orderDeadlineSort === "asc"
        ? "desc"
        : "asc";
    console.log("order sort", newOrder);
    setOrderDeadlineSort(newOrder);
    setOrderDeadlineColumn(property);
  };

  // Function to parse date strings to Date objects
  const parseDate = (dateString) => {
    // console.log("date string from parse", dateString);
    return new Date(dateString);
  };
  let sortedData = [];
  if (sortType === "expertDeadline") {
    sortedData = orderBy(
      orders,
      [
        (row) =>
          parseDate(
            row.expert_type === "expert"
              ? row.assigned_expert_deadline
              : row.order_end_date
          ),
      ],
      [expertDeadlineSort]
    );
  } else if (sortType === "orderDeadline") {
    sortedData = orderBy(
      orders,
      [(row) => parseDate(row.order_create_deadline)],
      [orderDeadlineSort]
    );
  } else {
    sortedData = orderBy(
      orders,
      [
        (row) =>
          parseDate(
            row.expert_type === "expert"
              ? row.assigned_expert_deadline
              : row.order_end_date
          ),
      ],
      ["asc"]
    );
  }

  const [searchQuery, setSearchQuery] = useState("");
  const getSearchValues = async (searchValue, tabType) => {
    const formData = new FormData();
    formData.append("status", currentStatus);
    formData.append("search", searchValue);
    var requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      `${FRONTEND_API}${
        tabType === "AssignedByAdmin"
          ? "adminassignedby"
          : "getOrdersBySubjectAndStatus"
      }?page=${page}&per_page=${rowsPerPage}`,
      requestOptions
    );

    const data = await response.json();
    setOrders(data.orders);
    setTotalDataCount(data.pagination.total_orders);
  };

  const handleSearchChange = (event) => {
    if (event.target.value === "" && bottomNavSub === "AssignedByAdmin") {
      fetchDataForSubject(currentStatus, bottomNavSub);
      setSearchQuery("");
    } else if (event.target.value === "") {
      fetchDataForSubject(currentStatus);
      setSearchQuery("");
    } else {
      setPage(1);
      setRowsPerPage(10);
      if (bottomNavSub === "AssignedByAdmin") {
        getSearchValues(event.target.value, bottomNavSub);
      } else {
        getSearchValues(event.target.value);
      }
      setSearchQuery(event.target.value);
    }
  };

  const serachedOrders = sortedData.filter(
    roles == "admin"
      ? (order) =>
          order.id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          order.subject?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          order.expert_id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          order.description
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          order.client_id?.toLowerCase().includes(searchQuery.toLowerCase())
      : (order) =>
          order.id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          order.subject?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          order.expert_id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          order.description
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          order.client_id?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    console.log("new page is here", newPage);
    setPage(newPage + 1);
    updateRowsPerPageOptions(newPage + 1, rowsPerPage);
    if (bottomNavSub === "AssignedByAdmin") {
      fetchDataForSubject(currentStatus, bottomNavSub, "", newPage + 1);
    } else {
      fetchDataForSubject(currentStatus, "", "", newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("value in all", event.target.value);
    const newRowsPerPage = parseInt(event.target.value, 10);
    console.log("row count inside page", newRowsPerPage);
    // Update the rowsPerPage state and use a callback to ensure the state is updated before calling the fetch function
    setRowsPerPage(newRowsPerPage);
    if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
      getSearchValues(searchQuery, bottomNavSub);
    } else if (bottomNavSub === "AssignedByAdmin") {
      fetchDataForSubject(currentStatus, bottomNavSub, newRowsPerPage);
    } else if (searchQuery !== "") {
      getSearchValues(searchQuery, "");
    } else {
      fetchDataForSubject(currentStatus, "", newRowsPerPage);
    }
  };
  const expertCurrencyChange = (event) => {
    setExpertCurrency(event.target.value);
  };

  const budgetCurrencyChange = (event) => {
    setBudgetCurrency(event.target.value);
  };
  const handleCloseAlert = () => {
    setAlertInfo({ open: false, message: "", severity: "success" });
  };

  const getTableData = async () => {
    try {
      const response = await axios.post(
        bottomNavSub === "UrgentDeadline"
          ? `${FRONTEND_API}list_urgdeadline/`
          : bottomNavSub === "HighPriority"
          ? `${FRONTEND_API}list_high_priority_deadline/`
          : bottomNavSub === "meetingSchedule"
          ? `${FRONTEND_API}list_meetingdeadline/`
          : "",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("response after fetch data", response);
      setTableValues(
        response.data.deadlines.filter((data) => data.is_status !== null)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.data.message === "No deadlines found") {
        setTableValues([]);
      }
      // setAlertInfo({
      //   open: true,
      //   message: error.response?.data?.message || "Unauthorized access",
      //   severity: "error",
      // });
    }
  };
  // sorting in table
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const [openDPR, setOpenDPR] = useState(false);

  const [dprId, setDprId] = useState("");

  const [dprType, setDprType] = useState("");
  const viewDPR = (id, dprtype) => {
    setDprId(id);
    setOpenDPR(true);
    setDprType(dprtype);
  };

  const handleCloseDpr = () => {
    if (openDPR) {
      setOpenDPR(false);
    }
  };

  // console.log("orderByColumn", orderByColumn);
  // console.log("condition", expertDeadlineSort);

  const editOrderDeadlineHandler = (orderDate) => {
    setOpenEditOrderDeadline(true);
    const dateObject = dayjs(orderDate);
    setOrderDeadlineDate(dateObject);
    setSelectedDate(dayjs(orderDate).toDate());
    console.log("orderdate is here", orderDate);
  };

  const closeOrderDeadlineHandler = () => {
    setOpenEditOrderDeadline(false);
  };

  // console.log("order date outside", orderDeadlineDate);
  // console.log("calendar status", openEditOrderDeadlinePicker);
  const handleUpdateOrderDeadline = () => {
    const ordersId = SelectedOrderId; // Replace this with the actual orderId you are using
    console.log("Order ID:", ordersId); // Log orderId to check its value

    const formData = new FormData();
    formData.append("updateCondition", "order_deadline");
    formData.append("order_deadline", selectedDate !== "" ? selectedDate : "");

    var requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    let responseStatus = 0;
    fetch(FRONTEND_API + "updateStatusWithId/".concat(ordersId), requestOptions)
      .then((response) => {
        if (response.status >= 200 && response.status < 500) {
          responseStatus = response.status;
          return response.json();
        } else {
          toast.error("internal server error");
        }
      })
      .then((data) => {
        // console.log("Success:", data);
        // setOpenEditOrderDeadlinePicker(false);
        if (responseStatus <= 299) {
          // console.log("inside order success");
          toast.success(
            data?.message ? data.message : "order updated successfully"
          );
        } else {
          toast.error(data?.message ? data.message : "Failed to update order");
        }
        closeOrderDeadlineHandler();
        if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
          getSearchValues(searchQuery, bottomNavSub);
        } else if (bottomNavSub === "AssignedByAdmin") {
          fetchDataForSubject(currentStatus, bottomNavSub);
        } else if (searchQuery !== "") {
          getSearchValues(searchQuery);
        } else {
          fetchDataForSubject(currentStatus);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong");
      });
  };

  //api call
  //closeOrderDeadlineHandler();
  //};

  const editExpertDeadlineHandler = (expertDate) => {
    const dateObject = dayjs(expertDate);
    console.log("date object is here", dateObject.$u === undefined);
    if (!expertDate || !dayjs(expertDate).isValid()) {
      setExpertDeadlineDate(dayjs().startOf("day"));
    } else {
      setExpertDeadlineDate(dateObject);
    }

    console.log("orderdate is here", expertDate);
    setOpenEditExpertDeadline(true);
  };

  const closeExpertDeadlineHandler = () => {
    setOpenEditExpertDeadline(false);
  };

  const handleUpdateExpertDeadline = () => {
    const ordersId = SelectedOrderId; // Replace this with the actual orderId you are using
    console.log("Order ID:", ordersId); // Log orderId to check its value
    console.log("selectedExpertType", selectedExpertType);
    const formData = new FormData();
    formData.append(
      "updateCondition",
      selectedExpertType ? selectedExpertType : "expert_deadline"
    );

    formData.append(
      selectedExpertType ? selectedExpertType : "expert_deadline",
      selectedDate !== "" ? selectedDate : ""
    );
    let responseStatus = 0;
    var requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    fetch(FRONTEND_API + "updateStatusWithId/".concat(ordersId), requestOptions)
      .then((response) => {
        if (response.status >= 200 && response.status < 500) {
          responseStatus = response.status;
          return response.json();
        } else {
          toast.error("internal server error");
        }
      })
      .then((data) => {
        console.log("Success:", data);
        setSelectedExpertType("");
        // setEditExpertDeadlinePicker(false);
        if (responseStatus <= 299) {
          toast.success(
            data?.message ? data.message : "order updated successfully"
          );
        } else {
          toast.error(data?.message ? data.message : "Failed to update order");
        }
        closeExpertDeadlineHandler();
        if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
          getSearchValues(searchQuery, bottomNavSub);
        } else if (bottomNavSub === "AssignedByAdmin") {
          fetchDataForSubject(currentStatus, bottomNavSub);
        } else if (searchQuery !== "") {
          getSearchValues(searchQuery);
        } else {
          fetchDataForSubject(currentStatus);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  //api call
  //closeExpertDeadlineHandler();
  //};

  const editSubjectHandler = (subject) => {
    setOpenEditSubject(true);
    setEditSubject(subject);
  };
  const closeSubjectHandler = () => {
    setOpenEditSubject(false);
  };
  const handleUpdateSubject = () => {
    const ordersId = SelectedOrderId; // Replace this with the actual orderId you are using
    console.log("Order ID:", ordersId, "task", editSubject); // Log orderId to check its value

    const formData = new FormData();
    formData.append("updateCondition", "task_subject");
    formData.append("task_subject", editSubject);

    var requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    let responseStatus = 0;
    fetch(FRONTEND_API + "updateStatusWithId/".concat(ordersId), requestOptions)
      .then((response) => {
        if (response.status >= 200 && response.status < 500) {
          responseStatus = response.status;
          return response.json();
        } else {
          toast.error("internal server error");
        }
      })
      .then((data) => {
        console.log("Success:", data);
        if (responseStatus <= 299) {
          toast.success(
            data?.message ? data.message : "order updated successfully"
          );
        } else {
          toast.error(data?.message ? data.message : "Failed to update order");
        }
        // setEditSubject(false);
        if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
          getSearchValues(searchQuery, bottomNavSub);
        } else if (bottomNavSub === "AssignedByAdmin") {
          fetchDataForSubject(currentStatus, bottomNavSub);
        } else if (searchQuery !== "") {
          getSearchValues(searchQuery);
        } else {
          fetchDataForSubject(currentStatus);
        }
        closeSubjectHandler();
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("something went wrong");
      });
  };

  //api call
  //closeSubjectHandler();
  //};
  const handleAddComment = (orderData) => {
    setCommentForOperation(orderData.comment_for_opt);
    setIsCommentModalOpen(true);
    setSelectedOrderId(orderData.id);
  };
  const handleCloseCommentModal = () => {
    setIsCommentModalOpen(false);
    setCommentForOperation("");
    setSelectedOrderId("");
  };
  console.log("comments for operation", commentForOperation);
  const handleUpdateOperationComment = async () => {
    // http://127.0.0.1:5000/orders/TH00134/comments
    const formData = new FormData();

    formData.append("comments", commentForOperation);
    const response = await fetch(
      `${FRONTEND_API}/orders/${SelectedOrderId}/comments`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    const data = await response.json();
    if (response.status === 200) {
      setIsCommentModalOpen(false);
      setCommentForOperation("");
      if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
        getSearchValues(searchQuery, bottomNavSub);
      } else if (bottomNavSub === "AssignedByAdmin") {
        fetchDataForSubject(currentStatus, bottomNavSub);
      } else if (searchQuery !== "") {
        getSearchValues(searchQuery);
      } else {
        fetchDataForSubject(currentStatus);
      }
    }
    console.log("data after adding comment", data);
  };

  useEffect(() => {
    if (
      bottomNavSub === "AssignedByAdmin" &&
      searchQuery !== "" &&
      roles === "lead"
    ) {
      getSearchValues(searchQuery, bottomNavSub);
    } else if (bottomNavSub === "AssignedByAdmin" && roles === "lead") {
      console.log("inside if of the admin assign");
      fetchDataForSubject("assigned", "AssignedByAdmin");
    } else if (bottomNavSub === "UrgentDeadline" && roles === "lead") {
      fetchDataForSubject(currentStatus, "UrgentDeadline");
    } else if (bottomNavSub === "HighPriority" && roles === "lead") {
      fetchDataForSubject(currentStatus, "HighPriority");
    } else if (bottomNavSub === "meetingSchedule" && roles === "lead") {
      fetchDataForSubject(currentStatus, "meetingSchedule");
    } else if (roles === "lead" && searchQuery !== "") {
      getSearchValues(searchQuery);
    } else if (roles === "lead") {
      fetchDataForSubject(currentStatus, "");
    } else if (searchQuery !== "") {
      getSearchValues(searchQuery);
    } else {
      fetchDataForSubject(currentStatus);
    }
  }, [currentStatus]);
  const updateRowsPerPageOptions = (page, rowsPerPage) => {
    const startOrderIndex = (page - 1) * rowsPerPage + 1;
    const remainingOrders = totalDataCount - startOrderIndex + 1;

    const filteredOptions = [10, 25, 100].filter((option) => {
      return option <= remainingOrders || (page === 1 && option === 100);
    });

    setRowsPerPageOptions(filteredOptions);
  };

  useEffect(() => {
    updateRowsPerPageOptions(page, rowsPerPage);
  }, [page, rowsPerPage, totalDataCount]);

  const todaysDate = new Date();
  console.log("selected date", selectedDate);
  console.log("currentStatus", currentStatus);
  console.log("order dadline sort condition", orderDeadlineSort == "asc");
  console.log("searchedOrders", serachedOrders);
  console.log("order sorting state", expertDeadlineSort);
  console.log("expert name is here", Qc_Expert_name);
  console.log("current tasks", tasks);
  console.log("currentStatus", currentStatus);
  console.log("all order from assigntask", orders);
  const handleAddTlComment = (orderData) => {
    setIsCommentModalOpen(true);
    setIsTlCommentModalOpen(true);
    setCommentForOperation(orderData.comment_for_tl);
    setSelectedOrderId(orderData.id);
  };
  const handleTlCommentUpdate = async () => {
    console.log("team lead comment working");
    const formData = new FormData();
    formData.append("comment_for_tl", commentForOperation);
    const response = await fetch(
      `${FRONTEND_API}/orders/${SelectedOrderId}/commentsfortl`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    const data = await response.json();
    if (response.status === 200) {
      setIsCommentModalOpen(false);
      setIsTlCommentModalOpen(false);
      setCommentForOperation("");
      if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
        getSearchValues(searchQuery, bottomNavSub);
      } else if (bottomNavSub === "AssignedByAdmin") {
        fetchDataForSubject(currentStatus, bottomNavSub);
      } else if (searchQuery !== "") {
        getSearchValues(searchQuery);
      } else {
        fetchDataForSubject(currentStatus);
      }
    }
    console.log("data after adding comment", data);
  };

  const teamLeadOperationFeatures = [
    "meeting schedule",
    "high priority",
    "urgent deadline",
  ];
  console.log("current operations tab", currentOperationTab);
  const highlightStyle = {
    backgroundColor: "#85c7ed",
    borderRadius: "20px",
    width: "100px",
    padding: "4px",
    marginLeft: "29px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const alertStyle = {
    backgroundColor: "#F98F90",
    borderRadius: "20px",
    padding: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const handleAddUrgentDeadline = (order) => {
    console.log("order inside urgent", order);
    setSelectedOrderId(order.id);
    setUrgentData({
      urgentDate: dayjs(order.urgent_deadline.urgent_deadline),
      urgentComment: order.urgent_deadline.urgent_deadline_comment,
    });
    setSelectedExpertId(order.expertId);
    setIsUrgentDeadlineModalOpen(true);
  };

  const handlAddHighPriority = (order) => {
    console.log("order inside schedule", order);
    setSelectedOrderId(order.id);
    setPriorityData({
      priorityDeadline: dayjs(
        order.high_priority_deadline.high_priority_deadline
      ),
      priorityComment: order.high_priority_deadline.high_priority_comment,
    });
    setSelectedExpertId(order.expertId);
    setIsHighPriorityModalOpen(true);
  };

  const handleAddMeetingSchedule = (order) => {
    console.log("order inside schedule", order);
    setSelectedOrderId(order.id);
    setmeetingData({
      deadline: dayjs(order.meeting_sche_deadline.meeting_sche_deadline),
      meetingComment: order.meeting_sche_deadline.meeting_sche_comment,
      meetingTime: order.meeting_sche_deadline.meeting_sche_time,
    });
    setSelectedExpertId(order.expertId);
    setIsAddMeetingScheduleModalOpen(true);
  };

  const handleCloseUrgentModal = () => {
    setIsUrgentDeadlineModalOpen(false);
    setSelectedOrderId("");
  };

  const handleCloseHighPriorityModal = () => {
    setIsHighPriorityModalOpen(false);
    setSelectedOrderId("");
  };
  const handleCloseMeetingScheduleModal = () => {
    setIsAddMeetingScheduleModalOpen(false);
    setCurrentOperationTab("");
    setSelectedOrderId("");
  };

  const handleConfirmUrgentDeadline = async () => {
    try {
      const formData = new FormData();
      console.log("meeting data is here", meetingData);
      formData.append(
        "urgent_deadline",
        urgentData.urgentDate.format("YYYY-MM-DD")
      );
      formData.append("urgent_deadline_comment", urgentData.urgentComment);
      formData.append("orders_id", SelectedOrderId);
      formData.append("expert_id", selectedExpertId);
      const response = await axios.post(
        `${FRONTEND_API}urgent_deadline`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status < 299) {
        toast.success(`${SelectedOrderId} added in urgent deadline`);
        setIsUrgentDeadlineModalOpen(false);
        if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
          getSearchValues(searchQuery, bottomNavSub);
        } else if (bottomNavSub === "AssignedByAdmin") {
          fetchDataForSubject(currentStatus, bottomNavSub);
        } else if (searchQuery !== "") {
          getSearchValues(searchQuery);
        } else {
          fetchDataForSubject(currentStatus);
        }
        setUrgentData({
          urgentDate: null,
          urgentComment: "",
        });
      } else if (response.status < 500) {
        toast.error(
          response.data.message
            ? response.data.message
            : "Provide data properly"
        );
        setIsHighPriorityModalOpen(false);
      } else {
        toast.error("internal server error");
      }
    } catch (error) {
      console.log("error is here", error);
      toast.error(error.response.data.message);
      setIsUrgentDeadlineModalOpen(false);
    }
  };

  const handleConfirmHighPriority = async () => {
    try {
      const formData = new FormData();
      formData.append("orders_id", SelectedOrderId);
      formData.append("expert_id", selectedExpertId);
      formData.append("high_priority_comment", priorityData.priorityComment);
      formData.append(
        "high_priority_deadline",
        priorityData.priorityDeadline.format("YYYY-MM-DD")
      );
      const response = await axios.post(
        `${FRONTEND_API}high_priority_deadline`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response after meeting post", response.data);
      if (response.status < 299) {
        toast.success(`${SelectedOrderId} added in high priority order`);
        setIsHighPriorityModalOpen(false);
        if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
          getSearchValues(searchQuery, bottomNavSub);
        } else if (bottomNavSub === "AssignedByAdmin") {
          fetchDataForSubject(currentStatus, bottomNavSub);
        } else if (searchQuery !== "") {
          getSearchValues(searchQuery);
        } else {
          fetchDataForSubject(currentStatus);
        }
        setPriorityData({
          priorityDeadline: null,
          priorityComment: "",
        });
      } else if (response.status < 500) {
        toast.error(
          response.data.message
            ? response.data.message
            : "Provide data properly"
        );
        setIsHighPriorityModalOpen(false);
      } else {
        toast.error("internal server error");
      }

      console.log("response after meeting post", response.data);
    } catch (error) {
      console.log("error is here", error);
      toast.error(error.response.data.message);
      setIsHighPriorityModalOpen(false);
    }
  };

  const handleConfirmAddMeetingSchedule = async () => {
    try {
      const formData = new FormData();
      formData.append(
        "meeting_sche_deadline",
        meetingData.deadline.format("YYYY-MM-DD")
      );
      formData.append(
        "meeting_sche_time",
        dayjs(meetingData.meetingTime).format("YYYY-MM-DD HH:mm:ss")
      );
      formData.append("meeting_sche_comment", meetingData.meetingComment);
      formData.append("orders_id", SelectedOrderId);
      formData.append("expert_id", selectedExpertId);
      const response = await axios.post(
        `${FRONTEND_API}meetingdeadline`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response after meeting post", response.data);
      if (response.status < 299) {
        toast.success(`${SelectedOrderId} added in meeting`);
        setIsAddMeetingScheduleModalOpen(false);
        if (bottomNavSub === "AssignedByAdmin" && searchQuery !== "") {
          getSearchValues(searchQuery, bottomNavSub);
        } else if (bottomNavSub === "AssignedByAdmin") {
          fetchDataForSubject(currentStatus, bottomNavSub);
        } else if (searchQuery !== "") {
          getSearchValues(searchQuery);
        } else {
          fetchDataForSubject(currentStatus);
        }
        setmeetingData({
          deadline: null,
          meetingTime: null,
          meetingComment: "",
        });
      } else if (response.status < 500) {
        console.log("inside error");
        toast.error(
          response.data.message
            ? response.data.message
            : "Provide data properly"
        );
      } else {
        toast.error("internal server error");
      }
    } catch (error) {
      console.log("error is here", error);
      toast.error(error.response.data.message);
      setIsAddMeetingScheduleModalOpen(false);
    }
  };
  const handleGetSelectedFeature = async (event, order) => {
    console.log("data from selection", event.target.value);
    try {
      const selectedFeature = event.target.value;
      setIsAddMeetingScheduleModalOpen(false);
      setIsHighPriorityModalOpen(false);
      setIsUrgentDeadlineModalOpen(false);

      // const response = await axios.post(
      //   selectedFeature === "meeting schedule"
      //     ? `${FRONTEND_API}list_meetingdeadline/`
      //     : selectedFeature === "high priority"
      //     ? `${FRONTEND_API}list_high_priority_deadline/`
      //     : selectedFeature === "urgent deadline"
      //     ? `${FRONTEND_API}list_urgdeadline/`
      //     : "",
      //   {},
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // console.log("data modal", response);

      // Force React to recognize this as a "new action"
      setTimeout(() => {
        setCurrentOperationTab(selectedFeature);
        console.log("order id from modal", order.id);
        setSelectedOrderId(order.id);
        setSelectedExpertId(order.expertId);
        // let responseData = response.data.deadlines.filter(
        //   (data) => data.orders_id === order.id
        // );

        // console.log("responseData is here", responseData);
        if (selectedFeature === "meeting schedule") {
          // setmeetingData({
          //   deadline: dayjs(responseData[0]?.meeting_sche_deadline),
          //   meetingComment: responseData[0]?.meeting_sche_comment,
          //   meetingTime: responseData[0]?.meeting_sche_time,
          // });
          setIsAddMeetingScheduleModalOpen(true); // Open modal
        } else if (selectedFeature === "high priority") {
          // setPriorityData({
          //   priorityDeadline: dayjs(responseData[0]?.high_priority_deadline),
          //   priorityComment: responseData[0]?.high_priority_comment,
          // });
          setIsHighPriorityModalOpen(true); // Open modal
        } else if (selectedFeature === "urgent deadline") {
          // setUrgentData({
          //   urgentDate: dayjs(responseData[0]?.urgent_deadline),
          //   urgentComment: responseData[0]?.urgent_deadline_comment,
          // });
          setIsUrgentDeadlineModalOpen(true); // Open modal
        }
      }, 0); // Delay modal reopening to ensure state reset
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };

  const TlOperationButtonStyles = {
    backgroundColor: "rgb(73, 112, 193)",
    borderRadius: "10px",
    padding: "6px",
    marginLeft: "29px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "10px",
    color: "white",
    fontSize: "12px",
    minWidth: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  console.log("team lead operation data", teamLeadOperationData);
  console.log("current status is here", currentStatus);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          marginBottom: 2,
          marginTop: 4,
          marginRight: 2,
        }}
      >
        {bottomNavSub !== "UrgentDeadline" &&
          bottomNavSub !== "HighPriority" &&
          bottomNavSub !== "meetingSchedule" && (
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          )}
      </Box>
      <Box sx={{ marginTop: 2, marginBottom: 10 }}>
        {roles != "expert" && roles != "lead" && roles != "otm" && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              margin: "25px",
              zIndex: 1,
            }}
          >
            <Fab color="secondary" aria-label="add" onClick={handleFabClick}>
              <AddIcon />
            </Fab>
          </Box>
        )}

        {orders && orders[0] && orders[0].Status ? (
          <Box
            sx={{
              width: "100vh",
              height: "100vh",
            }}
          >
            <p>
              {orders[0].Status.includes("None")
                ? "No data found"
                : orders[0].Status}
            </p>
          </Box>
        ) : orders && orders[0] && orders[0].Error ? (
          <Box
            sx={{
              width: "100vh",
              height: "100vh",
            }}
          >
            <p>No Orders to show</p>
          </Box>
        ) : (
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 5,
                marginTop: 2,
              }}
            >
              <>
                {bottomNavSub === "UrgentDeadline" ? (
                  <>
                    <TeamLeadOperationTable
                      tableType={bottomNavSub}
                      tableHeaders={["order ID", "Deadline", "Comment", "Done"]}
                      tableValues={tableValues}
                      searchQuery={searchQuery}
                      alertInfo={alertInfo}
                      getTableData={getTableData}
                      handleCloseAlert={handleCloseAlert}
                      setAlertInfo={setAlertInfo}
                    />
                  </>
                ) : bottomNavSub === "HighPriority" ? (
                  <>
                    <TeamLeadOperationTable
                      tableType={bottomNavSub}
                      tableHeaders={["OrderId", "Deadline", "Comment", "Done"]}
                      tableValues={tableValues}
                      searchQuery={searchQuery}
                      alertInfo={alertInfo}
                      getTableData={getTableData}
                      handleCloseAlert={handleCloseAlert}
                      setAlertInfo={setAlertInfo}
                    />
                  </>
                ) : bottomNavSub === "meetingSchedule" ? (
                  <>
                    <TeamLeadOperationTable
                      tableType={bottomNavSub}
                      tableHeaders={[
                        "Order Id",
                        "Deadline",
                        "Comment",
                        "Time",
                        "Done",
                      ]}
                      tableValues={tableValues}
                      searchQuery={searchQuery}
                      alertInfo={alertInfo}
                      getTableData={getTableData}
                      handleCloseAlert={handleCloseAlert}
                      setAlertInfo={setAlertInfo}
                    />
                  </>
                ) : (
                  <>
                    <TableContainer
                      component={Paper}
                      sx={{
                        marginRight: 2,
                      }}
                      aria-label="customized table"
                    >
                      <TablePagination
                        className="table-page"
                        component="div"
                        count={totalDataCount}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Orders per page:"
                        rowsPerPageOptions={rowsPerPageOptions}
                        labelDisplayedRows={({ from, to, count }) =>
                          `Showing ${from} to ${to} Orders of ${count} Order(s)`
                        }
                      />
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell>Order ID</StyledTableCell>
                            <StyledTableCell>Subject</StyledTableCell>
                            {roles == "admin" && (
                              <StyledTableCell
                                onClick={() => {
                                  setSortType("orderDeadline");
                                  handleRequestOrderSort("deadline");
                                }}
                              >
                                <Grid
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  Order Deadline
                                  {orderDeadlineSort === "asc" ? (
                                    <ArrowUpwardIcon />
                                  ) : orderDeadlineSort === "desc" ? (
                                    <ArrowDownwardIcon />
                                  ) : (
                                    <ArrowUpwardIcon />
                                  )}
                                </Grid>
                              </StyledTableCell>
                            )}
                            {roles == "admin" && (
                              <StyledTableCell>Student</StyledTableCell>
                            )}
                            {roles == "admin" || roles == "lead" ? (
                              <>
                                <StyledTableCell>
                                  {roles == "lead"
                                    ? "Order Assign To"
                                    : "Expert"}
                                </StyledTableCell>
                              </>
                            ) : (
                              ""
                            )}
                            <StyledTableCell>QC</StyledTableCell>
                            {currentStatus != "new order" &&
                            (roles == "admin" || roles == "lead") ? (
                              <StyledTableCell
                                onClick={() => {
                                  setSortType("expertDeadline");
                                  handleRequestSort("deadline");
                                }}
                              >
                                <Grid
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  {roles == "lead"
                                    ? "Team Lead Deadline"
                                    : "Expert Deadline"}
                                  {expertDeadlineSort === "asc" ? (
                                    <ArrowUpwardIcon />
                                  ) : expertDeadlineSort === "desc" ? (
                                    <ArrowDownwardIcon />
                                  ) : (
                                    <ArrowUpwardIcon />
                                  )}
                                </Grid>
                              </StyledTableCell>
                            ) : (
                              <></>
                            )}
                            <StyledTableCell>Order Status</StyledTableCell>
                            {currentStatus != "new Order" &&
                            roles !== "admin" &&
                            roles != "expert" ? (
                              <StyledTableCell>
                                Assigned Expert by Lead
                              </StyledTableCell>
                            ) : (
                              <></>
                            )}
                            {currentStatus != "new order" &&
                            roles !== "admin" &&
                            roles !== "expert" ? (
                              <StyledTableCell>
                                Assigned Deadline by Lead
                              </StyledTableCell>
                            ) : (
                              <></>
                            )}
                            {currentStatus != "new order" &&
                            roles != "admin" &&
                            roles !== "lead" ? (
                              <StyledTableCell>
                                <StyledTableSortLabel
                                  onClick={() => handleRequestSort("deadlines")}
                                >
                                  {roles == "expert"
                                    ? "Assigned DeadLine By Lead"
                                    : "Assigned DeadLine By Lead"}
                                </StyledTableSortLabel>
                              </StyledTableCell>
                            ) : (
                              <></>
                            )}
                            {/* {roles == "expert" && (
                            <StyledTableCell>Word Count</StyledTableCell>
                          )} */}
                            {roles != "admin" && (
                              <StyledTableCell>Comment</StyledTableCell>
                            )}
                            <StyledTableCell>Description</StyledTableCell>
                            {roles === "admin" || roles === "operations" ? (
                              <StyledTableCell>
                                Comment For Operation
                              </StyledTableCell>
                            ) : roles === "lead" ? (
                              <StyledTableCell>
                                Comment For TeamLead
                              </StyledTableCell>
                            ) : (
                              <></>
                            )}

                            {roles == "lead" && (
                              <StyledTableCell>
                                Team Lead Operation
                              </StyledTableCell>
                            )}
                            {roles == "admin" && (
                              <StyledTableCell>Operations</StyledTableCell>
                            )}
                          </StyledTableRow>
                        </TableHead>

                        <TableBody>
                          {console.log(orders)}
                          {sortedData.map((orderData) => (
                            <StyledTableRow
                              key={orderData.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell component="th" scope="row">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {orderData.id}
                                  {roles == "admin" && (
                                    <div
                                      role="button"
                                      onClick={() =>
                                        openOrderDetails(orderData.id)
                                      }
                                      style={{ marginLeft: "4px" }}
                                    >
                                      <InfoSharpIcon
                                        color="secondary"
                                        fontSize="small"
                                      />
                                    </div>
                                  )}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Grid
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"space-evenly"}
                                >
                                  {orderData.subject}
                                  {isEditAllowed && (
                                    <Button
                                      onClick={() => {
                                        editSubjectHandler(orderData.subject);
                                        setSelectedOrderId(orderData.id);
                                      }}
                                    >
                                      <ModeEditOutlineIcon />
                                    </Button>
                                  )}
                                </Grid>
                              </StyledTableCell>
                              {roles == "admin" && (
                                <StyledTableCell sx={{ minWidth: "180px" }}>
                                  <Grid
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"space-evenly"}
                                  >
                                    {handleDates(
                                      orderData.order_create_deadline
                                    )}
                                    <Button
                                      onClick={() => {
                                        editOrderDeadlineHandler(
                                          orderData.order_create_deadline
                                        );
                                        setSelectedOrderId(orderData.id);
                                      }}
                                    >
                                      <EditCalendarIcon />
                                    </Button>
                                  </Grid>
                                </StyledTableCell>
                              )}
                              {roles == "admin" && (
                                <StyledTableCell>
                                  {orderData.client_id}{" "}
                                </StyledTableCell>
                              )}
                              {roles == "admin" || roles == "lead" ? (
                                <StyledTableCell>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {orderData.expert_id == "" ||
                                    orderData.expert_id == null ? (
                                      <span style={{ marginRight: "5px" }}>
                                        Unassigned
                                      </span>
                                    ) : (
                                      <>
                                        <span style={{ marginRight: "5px" }}>
                                          {orderData.expert_id}
                                        </span>
                                      </>
                                    )}

                                    <div
                                      role="button"
                                      onClick={() =>
                                        handleExpertUpdate(orderData)
                                      }
                                    >
                                      <PeopleAltIcon />
                                    </div>
                                    {orderData.expert_id == "" ||
                                    orderData.expert_id == null ? (
                                      " "
                                    ) : (
                                      <>
                                        <Button
                                          variant="contained"
                                          type="submit"
                                          color="info"
                                          onClick={() =>
                                            viewDPR(
                                              orderData.expertId,
                                              orderData.expert_type === "lead"
                                                ? "adminLeadDpr"
                                                : "teamMemberDpr"
                                            )
                                          }
                                          size="small"
                                          sx={{
                                            marginRight: 2,
                                            marginLeft: 2,
                                          }}
                                        >
                                          DPR
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </StyledTableCell>
                              ) : (
                                <></>
                              )}
                              <StyledTableCell>
                                {(() => {
                                  if (
                                    (orderData.qc_expert === "" ||
                                      orderData.qc_expert === null) &&
                                    roles == "admin"
                                  ) {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "100px",
                                          }}
                                          role="button"
                                          onClick={() =>
                                            handleDCExpertUpdate(
                                              orderData.id,
                                              orderData.order_status
                                            )
                                          }
                                        >
                                          <div style={{ marginRight: "5px" }}>
                                            Unassigned
                                          </div>
                                          <PeopleAltIcon />
                                        </div>
                                      </>
                                    );
                                  } else if (
                                    (orderData.qc_expert !== "" ||
                                      orderData.qc_expert !== null) &&
                                    roles == "admin"
                                  ) {
                                    return (
                                      <>
                                        <div
                                          role="button"
                                          onClick={() =>
                                            handleDCExpertUpdate(
                                              orderData.id,
                                              orderData.order_status
                                            )
                                          }
                                        >
                                          <span style={{ marginRight: "5px" }}>
                                            {orderData.qc_expert}
                                          </span>
                                          <PeopleAltIcon />
                                        </div>
                                      </>
                                    );
                                  } else if (
                                    orderData?.qc_expert_id?.toString() ===
                                    userId?.toString()
                                  ) {
                                    return (
                                      <>
                                        <span>Qc only</span>
                                      </>
                                    );
                                  } else if (
                                    orderData?.qc_expert == "" ||
                                    orderData?.qc_expert !== null
                                  ) {
                                    return (
                                      <>
                                        <span style={{ marginRight: "5px" }}>
                                          {orderData.qc_expert}
                                        </span>
                                      </>
                                    );
                                  } else if (
                                    orderData?.qc_expert === "" ||
                                    orderData?.qc_expert === null
                                  ) {
                                    return (
                                      <>
                                        <span style={{ marginRight: "5px" }}>
                                          -
                                        </span>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <span style={{ marginRight: "5px" }}>
                                          Unassigned
                                        </span>
                                      </>
                                    );
                                  }
                                })()}
                              </StyledTableCell>

                              {currentStatus != "new order" &&
                              (roles == "admin" || roles == "lead") ? (
                                <StyledTableCell sx={{ minWidth: "180px" }}>
                                  <div
                                    style={
                                      roles === "lead" &&
                                      (formattedToday ===
                                        handleDates(orderData.order_end_date) ||
                                        formattedYesterday ===
                                          handleDates(
                                            orderData.order_end_date
                                          ) ||
                                        formattedDayBeforeYesterday ===
                                          handleDates(orderData.order_end_date))
                                        ? highlightStyle
                                        : handleDates(
                                            orderData.expert_type === "lead"
                                              ? orderData.order_end_date
                                              : orderData.assigned_expert_deadline
                                          ) === handleDates(todaysDate)
                                        ? alertStyle
                                        : {}
                                    }
                                  >
                                    <Grid
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      {roles === "lead" ? (
                                        <span
                                          style={{
                                            color:
                                              formattedToday ===
                                                handleDates(
                                                  orderData.order_end_date
                                                ) ||
                                              formattedYesterday ===
                                                handleDates(
                                                  orderData.order_end_date
                                                ) ||
                                              formattedDayBeforeYesterday ===
                                                handleDates(
                                                  orderData.order_end_date
                                                )
                                                ? "#115780"
                                                : "inherit",
                                          }}
                                        >
                                          {handleDates(
                                            orderData.order_end_date
                                          )}
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            color:
                                              handleDates(
                                                orderData.expert_type === "lead"
                                                  ? orderData.order_end_date
                                                  : orderData.assigned_expert_deadline
                                              ) === handleDates(todaysDate)
                                                ? "red"
                                                : "inherit",
                                            marginLeft: "18px",
                                          }}
                                        >
                                          {handleDates(
                                            orderData.expert_type === "lead"
                                              ? orderData.order_end_date
                                              : orderData.assigned_expert_deadline
                                          )}
                                        </span>
                                      )}
                                      {isEditAllowed && (
                                        <Button
                                          onClick={() => {
                                            editExpertDeadlineHandler(
                                              orderData.expert_type === "lead"
                                                ? orderData.order_end_date
                                                : orderData.assigned_expert_deadline
                                            );
                                            console.log(
                                              "assigned_expert_deadline is here",
                                              orderData.assigned_expert_deadline
                                            );
                                            if (
                                              orderData.expert_type === "expert"
                                            ) {
                                              setSelectedExpertType(
                                                "assigned_expert_deadline"
                                              );
                                            }
                                            setSelectedOrderId(orderData.id);
                                          }}
                                        >
                                          <EditCalendarIcon />{" "}
                                        </Button>
                                      )}
                                      {/* <Button
                                        onClick={() => {
                                          editExpertDeadlineHandler(
                                            orderData.order_end_date
                                          );
                                          setSelectedOrderId(orderData.id);
                                        }}
                                      >
                                        {isEditAllowed && <EditCalendarIcon />}
                                      </Button> */}
                                    </Grid>
                                  </div>
                                </StyledTableCell>
                              ) : null}
                              <StyledTableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {orderData.order_status != null
                                    ? orderData.order_status
                                    : "Unassigned"}
                                  {roles == "admin" ? (
                                    <div
                                      role="button"
                                      onClick={() =>
                                        handleStatusUpdate(
                                          orderData.id,
                                          orderData.order_status
                                        )
                                      }
                                    >
                                      <UpdateSharpIcon />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </StyledTableCell>
                              {currentStatus != "new Order" &&
                              roles !== "admin" &&
                              roles == "lead" ? (
                                <StyledTableCell>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {orderData.assigned_expert}
                                    {orderData.assigned_expert_id == "" ||
                                    orderData.assigned_expert_id == null ? (
                                      " "
                                    ) : (
                                      <>
                                        <Button
                                          variant="contained"
                                          type="submit"
                                          color="info"
                                          onClick={() =>
                                            viewDPR(
                                              orderData.assigned_expert_id
                                            )
                                          }
                                          size="small"
                                          sx={{
                                            marginRight: 2,
                                            marginLeft: 2,
                                          }}
                                        >
                                          DPR
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </StyledTableCell>
                              ) : (
                                <></>
                              )}

                              {currentStatus != "new order" &&
                              roles !== "admin" &&
                              roles !== "expert" ? (
                                <StyledTableCell>
                                  {handleDates(
                                    orderData.assigned_expert_deadline
                                  )}
                                </StyledTableCell>
                              ) : (
                                <></>
                              )}

                              {currentStatus !== "new order" &&
                                roles !== "admin" &&
                                roles !== "lead" && (
                                  // Array.isArray(
                                  //   orderData?.deadlines_with_word_count
                                  // )
                                  <StyledTableCell>
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      color="info"
                                      onClick={() =>
                                        viewDPR(orderData.assigned_expert_id)
                                      }
                                      size="small"
                                      sx={{ marginRight: 2, marginLeft: 2 }}
                                    >
                                      DPR
                                    </Button>
                                  </StyledTableCell>
                                )}

                              {/* {currentStatus !== "new order" &&
                                roles !== "admin" &&
                                roles !== "lead" &&
                                Array.isArray(
                                  orderData?.deadlines_with_word_count
                                ) && (
                                  <StyledTableCell>
                                    {orderData.deadlines_with_word_count.map(
                                      (deadline, index) => (
                                        <div key={index}>
                                          {deadline.expert_word_count
                                            ? deadline.expert_word_count
                                            : null}
                                        </div>
                                      )
                                    )}
                                  </StyledTableCell>
                                )} */}
                              {roles != "admin" && (
                                <StyledTableCell>
                                  {roles === "expert"
                                    ? orderData.comment_forteam_member
                                    : orderData.expert_type === "lead"
                                    ? orderData.comments
                                    : orderData.expert_type === "expert"
                                    ? orderData.comment_forteam_member
                                    : orderData.comments}
                                </StyledTableCell>
                              )}

                              <StyledTableCell>
                                {orderData.description}
                              </StyledTableCell>
                              {(roles === "admin" || roles === "operation") && (
                                <StyledTableCell>
                                  <Grid
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <p>
                                      {orderData.comment_for_opt !== "NuLL" ? (
                                        <>{orderData.comment_for_opt}</>
                                      ) : (
                                        <></>
                                      )}
                                    </p>
                                    {roles === "admin" && (
                                      <Button
                                        onClick={() =>
                                          handleAddComment(orderData)
                                        }
                                      >
                                        <AddCommentIcon />
                                      </Button>
                                    )}
                                  </Grid>
                                </StyledTableCell>
                              )}
                              {roles === "lead" && (
                                <StyledTableCell>
                                  <Grid
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <p>
                                      {orderData.comment_for_tl !== null ? (
                                        <>{orderData.comment_for_tl}</>
                                      ) : (
                                        <></>
                                      )}
                                    </p>
                                    <Button
                                      onClick={() =>
                                        handleAddTlComment(orderData)
                                      }
                                    >
                                      <AddCommentIcon />
                                    </Button>
                                  </Grid>
                                </StyledTableCell>
                              )}

                              {roles === "lead" && (
                                <StyledTableCell>
                                  <Grid
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    {/* <button
                                        style={TlOperationButtonStyles}
                                        onClick={() =>
                                          handleAddUrgentDeadline(orderData)
                                        }
                                      >
                                        urgent deadline
                                      </button>
                                      <button
                                        style={TlOperationButtonStyles}
                                        onClick={() =>
                                          handlAddHighPriority(orderData)
                                        }
                                      >
                                        High priority
                                      </button>
                                      <button
                                        style={TlOperationButtonStyles}
                                        onClick={() =>
                                          handleAddMeetingSchedule(orderData)
                                        }
                                      >
                                        Meeting schedule
                                      </button> */}
                                    <FormControl
                                      variant="outlined"
                                      sx={{
                                        width: "130px",
                                      }}
                                    >
                                      <InputLabel
                                        id="status-filter-label"
                                        sx={{
                                          top: "-6px", // Adjust the vertical positioning of the label
                                          fontSize: "14px", // Adjust font size if needed
                                        }}
                                      >
                                        Operations
                                      </InputLabel>
                                      <Select
                                        labelId="status-filter-label"
                                        label="operations"
                                        value={""}
                                        onChange={(event) =>
                                          handleGetSelectedFeature(
                                            event,
                                            orderData
                                          )
                                        }
                                        size="small"
                                        sx={{
                                          "& .MuiSelect-select": {
                                            paddingTop: "10px", // Adjust padding to ensure label is vertically centered
                                            paddingBottom: "10px",
                                          },
                                        }}
                                      >
                                        {teamLeadOperationFeatures.map(
                                          (item) => (
                                            <MenuItem key={item} value={item}>
                                              {item}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </StyledTableCell>
                              )}
                              {roles == "admin" && orderData.id != null && (
                                <StyledTableCell>
                                  <div className="container-in">
                                    {/* <div
                                      className="container-icon"
                                      role="button"
                                      onClick={() => handleModalEdit(orderData.id)}
                                    > */}
                                    {/* <DriveFileRenameOutlineOutlinedIcon fontSize="small" /> */}
                                    {/* <div className="text">Edit</div>
                                    </div> */}
                                    <div
                                      className="container-icon"
                                      role="button"
                                      onClick={() =>
                                        handleClickOpenWarn(orderData.id)
                                      }
                                    >
                                      <DeleteForeverOutlinedIcon fontSize="small" />
                                      <div className="text">Delete</div>
                                    </div>
                                  </div>
                                </StyledTableCell>
                              )}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </>
            </Box>
          </div>
        )}
        {isOrderDetailsOpen && (
          <OrderDetails
            open={isOrderDetailsOpen}
            orders={selectedOrder}
            onClose={closeOrderDetails}
          />
        )}
      </Box>

      <BottomNavigation
        showLabels={true}
        value={bottomNavSub} //subject
        onChange={(event, newValue) => {
          console.log("value from bottom", newValue);
          setBottomNavSub(newValue);
          setPage(1);
          // setRowsPerPage(parseInt(10));
          if (
            roles === "lead" &&
            searchQuery !== "" &&
            newValue === "AssignedByAdmin"
          ) {
            setCurrentStatus("assigned");
            // fetchDataForSubject("assigned", "AssignedByAdmin");
            getSearchValues(searchQuery, "AssignedByAdmin");
          } else if (roles === "lead" && newValue === "AssignedByAdmin") {
            setCurrentStatus("assigned");
            fetchDataForSubject("assigned", "AssignedByAdmin");
          } else if (
            roles === "lead" &&
            (newValue === "UrgentDeadline" ||
              newValue === "HighPriority" ||
              newValue === "meetingSchedule")
          ) {
            setCurrentStatus("assigned");
            getTableData();
          } else if (roles === "lead" && searchQuery === "") {
            console.log("else case working");
            fetchDataForSubject(newValue, "");
          } else if (roles === "lead" && searchQuery !== "") {
            console.log("else case working");
            getSearchValues(searchQuery);
          } else if (roles === "admin" && searchQuery !== "") {
            getSearchValues(searchQuery);
          } else {
            console.log("inside else bottom");
            fetchDataForSubject(newValue);
          }
        }}
        sx={{
          position: "fixed",
          bottom: 0,
          marginBottom: 2,
          display: "inline-flex",
          width: roles === "lead" ? "94%" : "100%",
          left: roles === "lead" ? 78 : 0,
          justifyContent: "center",
          backgroundColor: "transparent",
        }}
      >
        {roles == "admin" && (
          <StyledBottomNavigationAction
            label="New Order"
            onClick={() => {
              setCurrentStatus("new order");
              setSortType("orderDeadline");
              setExpertDeadlineSort("asc");
              setOrderDeadlineSort("asc");
            }}
            value="new order"
          />
        )}
        {roles == "lead" && (
          <StyledBottomNavigationAction
            label="Asigned By Admin"
            onClick={() => {
              // setCurrentStatus("assigned");
              setSortType("orderDeadline");
              setExpertDeadlineSort("asc");
              setOrderDeadlineSort("asc");
            }}
            value="AssignedByAdmin"
          />
        )}
        <StyledBottomNavigationAction
          label="Assigned"
          onClick={() => {
            setCurrentStatus("assigned");
            setSortType("expertDeadline");
            console.log("inside assigned");
            setExpertDeadlineSort("asc");
            setOrderDeadlineSort("asc");
            if (searchQuery !== "") {
              getSearchValues(searchQuery);
            }
          }}
          value="assigned"
        />
        <StyledBottomNavigationAction
          label="QC"
          onClick={() => {
            setCurrentStatus("qc");
            setSortType("expertDeadline");
            setExpertDeadlineSort("asc");
            setOrderDeadlineSort("asc");
          }}
          value="qc"
        />
        <StyledBottomNavigationAction
          label="Rework"
          onClick={() => {
            setCurrentStatus("rework");
            setSortType("expertDeadline");
            setExpertDeadlineSort("asc");
            setOrderDeadlineSort("asc");
          }}
          value="rework"
        />
        <StyledBottomNavigationAction
          label="Completed"
          onClick={() => {
            setSortType("expertDeadline");
            setCurrentStatus("pass");
            setExpertDeadlineSort("asc");
            setOrderDeadlineSort("asc");
            if (searchQuery !== "") {
              getSearchValues(searchQuery);
            }
          }}
          value="pass"
        />
        <StyledBottomNavigationAction
          label="Failed"
          onClick={() => {
            setCurrentStatus("fail");
            setSortType("expertDeadline");
            setExpertDeadlineSort("asc");
            setOrderDeadlineSort("asc");
          }}
          value="fail"
        />
        {roles == "lead" && (
          <StyledBottomNavigationAction
            label="Urgent Deadline"
            onClick={() => {
              setCurrentStatus("assigned");
              setSortType("expertDeadline");
              setExpertDeadlineSort("asc");
              setOrderDeadlineSort("asc");
            }}
            value="UrgentDeadline"
          />
        )}
        {roles == "lead" && (
          <StyledBottomNavigationAction
            label="High Priority"
            onClick={() => {
              setSortType("expertDeadline");
              setExpertDeadlineSort("asc");
              setOrderDeadlineSort("asc");
            }}
            value="HighPriority"
          />
        )}
        {roles == "lead" && (
          <StyledBottomNavigationAction
            label="Meeting schedule"
            onClick={() => {
              setCurrentStatus("assigned");
              setSortType("expertDeadline");
              setExpertDeadlineSort("asc");
              setOrderDeadlineSort("asc");
            }}
            value="meetingSchedule"
          />
        )}
      </BottomNavigation>

      {/* validation Dialog */}
      {/* <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}>
                <DialogTitle>Form Validation Failed</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill in all required fields correctly.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        color='primary'>
                        OK
                    </Button>
                </DialogActions>
            </Dialog> */}
      {openDPR ? (
        <DPRConsole
          open={openDPR}
          handleClose={handleCloseDpr}
          dprType={dprType}
          userId={dprId}
          getAllData={() => fetchDataForSubject(currentStatus)}
        />
      ) : (
        ""
      )}
      <ToastContainer />
      <CommonModal
        type={openDCExpertsDialog || openTask}
        isOpen={
          openEdit
            ? openEdit
            : openDCExpertsDialog
            ? openDCExpertsDialog
            : openTask
            ? openTask
            : openWarn
        }
        handleClose={
          openEdit
            ? handleCloseEdit
            : openDCExpertsDialog
            ? closeQCExpertDialog
            : openTask
            ? handleClose
            : handleCloseWarn
        }
        handleConfirm={
          openEdit
            ? handleQCOrderUpdate
            : openDCExpertsDialog
            ? handleUpdateQCExpert
            : openTask
            ? handleQCUpdate
            : handleDeleteOrder
        }
        content={
          openDCExpertsDialog ? (
            <>
              <FormControl fullWidth>
                <Box>
                  <FormControl fullWidth sx={{ marginTop: 1 }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assign Task Freelanceror QC
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={userType}
                      onChange={handleUserTypeChange}
                    >
                      <FormControlLabel
                        value="lead"
                        control={<Radio />}
                        label="Team Lead"
                      />

                      <FormControlLabel
                        value="permanent"
                        control={<Radio />}
                        label="Permanent"
                      />
                      <FormControlLabel
                        value="freelancer"
                        control={<Radio />}
                        label="Freelancer"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl sx={{ width: "250px", marginTop: "10px" }}>
                    <InputLabel>Expert</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={Qc_Expert}
                      label="Experts"
                      variant="outlined"
                      error={expertValid == false}
                      helperText={expertValid == false && "Select Expert"}
                      onChange={handleChange}
                    >
                      {expert.map((data) => (
                        <MenuItem value={data.id}>
                          {data.firstname} {data.lastname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </FormControl>
            </>
          ) : (
            ""
          )
        }
      />
      <CommonModal
        type={
          openEdit ||
          openExpertsDialog ||
          openTask ||
          openEditOrderDeadline ||
          openEditExpertDeadline ||
          openEditSubject ||
          isCommentModalOpen ||
          isHighPriorityModalOpen ||
          isUrgentDeadlineModalOpen ||
          isAddMeetingScheduleModalOpen
            ? "edit"
            : "delete"
        }
        title={
          openEdit
            ? "Edit Order"
            : openExpertsDialog
            ? "Assign Expert"
            : openTask
            ? "Select status from the List to Update"
            : openEditOrderDeadline
            ? "Update Order deadline"
            : openEditExpertDeadline
            ? "Update Expert deadline"
            : openEditSubject
            ? "Update subject"
            : isCommentModalOpen
            ? "Comment"
            : isUrgentDeadlineModalOpen
            ? "Urgent Deadline"
            : isHighPriorityModalOpen
            ? "Priority section"
            : isAddMeetingScheduleModalOpen
            ? "Meeting schedule"
            : "Are you sure you want to delete this orders?"
        }
        handleClose={
          openEdit
            ? handleCloseEdit
            : openExpertsDialog
            ? closeExpertDialog
            : openTask
            ? handleClose
            : openEditOrderDeadline
            ? closeOrderDeadlineHandler
            : openEditExpertDeadline
            ? closeExpertDeadlineHandler
            : openEditSubject
            ? closeSubjectHandler
            : isCommentModalOpen
            ? handleCloseCommentModal
            : isUrgentDeadlineModalOpen
            ? handleCloseUrgentModal
            : isHighPriorityModalOpen
            ? handleCloseHighPriorityModal
            : isAddMeetingScheduleModalOpen
            ? handleCloseMeetingScheduleModal
            : handleCloseWarn
        }
        isOpen={
          openEdit
            ? openEdit
            : openExpertsDialog
            ? openExpertsDialog
            : openTask
            ? openTask
            : openEditOrderDeadline
            ? openEditOrderDeadline
            : openEditExpertDeadline
            ? openEditExpertDeadline
            : openEditSubject
            ? openEditSubject
            : isCommentModalOpen
            ? isCommentModalOpen
            : isUrgentDeadlineModalOpen
            ? isUrgentDeadlineModalOpen
            : isHighPriorityModalOpen
            ? isHighPriorityModalOpen
            : isAddMeetingScheduleModalOpen
            ? isAddMeetingScheduleModalOpen
            : openWarn
        }
        handleConfirm={
          openEdit
            ? handleOrderUpdate
            : openExpertsDialog
            ? handleUpdateExpert
            : openTask
            ? handleUpdate
            : openEditOrderDeadline
            ? handleUpdateOrderDeadline
            : openEditExpertDeadline
            ? handleUpdateExpertDeadline
            : openEditSubject
            ? handleUpdateSubject
            : isTlCommentModalOpen
            ? handleTlCommentUpdate
            : isCommentModalOpen
            ? handleUpdateOperationComment
            : isUrgentDeadlineModalOpen
            ? handleConfirmUrgentDeadline
            : isHighPriorityModalOpen
            ? handleConfirmHighPriority
            : isAddMeetingScheduleModalOpen
            ? handleConfirmAddMeetingSchedule
            : handleDeleteOrder
        }
        modalIcon={
          openEdit
            ? editIcon
            : openExpertsDialog
            ? AssingExpert
            : openTask ||
              openEditSubject ||
              isCommentModalOpen ||
              isHighPriorityModalOpen ||
              isAddMeetingScheduleModalOpen ||
              isUrgentDeadlineModalOpen
            ? EditTaskIcon
            : openEditOrderDeadline || openEditExpertDeadline
            ? calendarIcon
            : deleteModalIcon
        }
        subContent={
          openWarn &&
          " After selecting this step this user will be permanently deleted."
        }
        content={
          openEdit ? (
            <>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    sx={{
                      marginTop: 3,
                      m: 1,
                    }}
                  >
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Subject
                    </FormLabel>

                    <TextField
                      id="outlined-basic"
                      value={subject}
                      onChange={handleSubjectChange}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl sx={{ m: 1 }} fullWidth>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Task Deadline
                    </FormLabel>
                    <ButtonContainer
                      role="button"
                      onClick={handleButtonClick}
                      size="small"
                      ref={buttonRef}
                    >
                      <span style={{ marginLeft: "10px" }}>
                        {endDate.format("YYYY-MM-DD")}
                      </span>
                      <IconButton
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          alignContent: "end",
                        }}
                      >
                        <CalendarTodayIcon />
                      </IconButton>
                    </ButtonContainer>

                    {openDatePicker && (
                      <div
                        style={{
                          position: "absolute",
                          zIndex: 99,
                          top:
                            buttonRef.current.offsetTop +
                            buttonRef.current.offsetHeight +
                            "px",
                          left: buttonRef.current.offsetLeft + "px",
                          backgroundColor: "#fff", // Set a background color for the calendar container
                          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateCalendar
                            value={endDate}
                            onChange={(newValue) =>
                              handleEndDateChange(newValue)
                            }
                            renderInput={() => null} // Hide the input inside the calendar
                            PopperProps={{
                              style: { zIndex: 99, backgroundColor: "#fff" }, // Adjust z-index as needed
                            }}
                            minDate={today}
                            sx={{
                              ".Mui-selected": {
                                backgroundColor: "red", // Customize the background color of selected days
                              },
                              ".MuiPickersDay-day": {
                                color: "green", // Customize the color of the calendar days
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    sx={{
                      m: 1,
                      marginTop: 2,
                    }}
                  >
                    {/* <TextField
                                            required
                                            id='wordCount'
                                            label='Word Count'
                                            name='wordCount'
                                            value={wordCount}
                                            onChange={onChangeWordCount}
                                            error={wordCountValid == false}
                                            helperText={
                                                wordCountValid == false && 'Invalid word count'
                                            }
                                        /> */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                marginTop={2}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={3}>
                  <FormControl
                    fullWidth
                    sx={{
                      m: 1,
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={expertCurrency}
                      label="currency"
                      onChange={expertCurrencyChange}
                    >
                      {currencies.map((data) => (
                        <MenuItem value={data.value}>{data.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={8}>
                  <FormControl
                    fullWidth
                    sx={{
                      m: 1,
                    }}
                  >
                    <TextField
                      id="expertPrice"
                      label="Expert Price"
                      name="expertPrice"
                      value={expertPrice}
                      onChange={onChangeExpertPrice}
                      error={expertPriceValid == false}
                      helperText={expertPriceValid == false && "Invalid Price"}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    fullWidth
                    sx={{
                      marginTop: 2,
                      m: 1,
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={budgetCurrency}
                      label="currency"
                      onChange={budgetCurrencyChange}
                    >
                      {currencies.map((data) => (
                        <MenuItem value={data.value}>{data.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl
                    fullWidth
                    sx={{
                      marginTop: 3,
                      m: 1,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      value={Vendor_budget}
                      onChange={handleVendorBudgetChange}
                      variant="outlined"
                      error={vendorBudgetValid == false}
                      helperText={
                        vendorBudgetValid == false && "Invalid budget"
                      }
                      label="Budget"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth sx={{ m: 1, marginTop: 3 }}>
                <TextField
                  id="outlined-basic"
                  value={Description}
                  onInput={(e) => {
                    setDescription(e.target.value);
                  }}
                  label="Comment"
                  variant="outlined"
                />
              </FormControl>
            </>
          ) : openExpertsDialog ? (
            <>
              <FormControl fullWidth>
                <Box>
                  <FormControl fullWidth sx={{ marginTop: 1 }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Assign Task
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={userType}
                      onChange={handleUserTypeChange}
                    >
                      <FormControlLabel
                        value="lead"
                        control={<Radio />}
                        label="Team Lead"
                      />

                      <FormControlLabel
                        value="permanent"
                        control={<Radio />}
                        label="Permanent"
                      />
                      <FormControlLabel
                        value="freelancer"
                        control={<Radio />}
                        label="Freelancer"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl sx={{ width: "250px", marginTop: "10px" }}>
                    <InputLabel>Expert</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={Qc_Expert_name}
                      label="Experts"
                      variant="outlined"
                      error={expertValid == false}
                      helperText={expertValid == false && "Select Expert"}
                      onChange={handleChangeQc}
                    >
                      {expert.map((data) => (
                        <MenuItem value={data.id}>
                          {data.firstname} {data.lastname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box sx={{ height: "10px" }} />{" "}
                  {/* This will add space between the fields */}
                  <FormControl sx={{ width: "250px", marginTop: "10px" }}>
                    {/* <InputLabel>Comments</InputLabel> */}

                    <TextField
                      required
                      id="demo1-simple-select"
                      value={Comments}
                      label="Comment"
                      onChange={handleChangecomment}
                    />
                  </FormControl>
                  <FormControl sx={{ marginTop: "10px" }} fullWidth>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Task Deadline
                    </FormLabel>
                    <div>
                      {tasks.map((task, index) => (
                        <div key={index}>
                          <ButtonContainer
                            role="button"
                            onClick={() => handleInvoiceButtonClick(index)}
                            size="small"
                            ref={invoiceButtonRef}
                          >
                            {console.log(
                              "date from JSX",
                              task.invoiceDate
                                ? dayjs(task?.invoiceDate).format("YYYY-MM-DD")
                                : ""
                            )}
                            {task.invoiceDate
                              ? dayjs(task?.invoiceDate).format("YYYY-MM-DD")
                              : ""}
                            <IconButton
                              sx={{
                                display: "flex",
                                alignItems: "end",
                                alignContent: "end",
                              }}
                            >
                              <CalendarTodayIcon />
                            </IconButton>
                          </ButtonContainer>

                          {openInvoiceDatePicker && (
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 99,
                                top:
                                  invoiceButtonRef.current.offsetTop +
                                  invoiceButtonRef.current.offsetHeight +
                                  -170 +
                                  "px",
                                left:
                                  invoiceButtonRef.current.offsetLeft +
                                  250 +
                                  "px",
                                backgroundColor: "#fff",
                                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                                transform: "scale(0.7)", // Adjust the scale to make the datepicker smaller
                                transformOrigin: "top left", // Ensure the scaling happens from the top-left corner
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* {console.log(task.invoiceDate)} */}
                                <DateCalendar
                                  value={
                                    task.invoiceDate
                                      ? dayjs(task.invoiceDate)
                                      : null
                                  }
                                  onChange={(newValue) => {
                                    console.log(
                                      "value from calendar",
                                      newValue
                                    );
                                    console.log("index from inner", index);
                                    handleInvoiceDateChange(newValue);
                                  }}
                                  renderInput={() => null}
                                  PopperProps={{
                                    style: {
                                      zIndex: 99,
                                      backgroundColor: "#fff",
                                    },
                                  }}
                                  minDate={
                                    task?.invoiceDate
                                      ? task?.invoiceDate
                                      : today
                                  }
                                  sx={{
                                    "& .MuiCalendarPicker-root": {
                                      transform: "scale(0.8)", // Adjust the scale to make it smaller
                                      transformOrigin: "top left", // Ensure the scaling happens from the top-left corner
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          )}

                          <FormControl sx={{ marginTop: 2, marginBottom: 2 }}>
                            <TextField
                              required
                              id={`wordCount-${index}`}
                              label="Word Count"
                              name={`wordCount-${index}`}
                              value={task.wordCount}
                              onChange={(event) =>
                                onChangeWordCount(event, index)
                              }
                            />
                          </FormControl>

                          <IconButton
                            sx={{ marginTop: 3 }}
                            onClick={() => removeTask(index)}
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ))}

                      <Button variant="outlined" onClick={addNewTask}>
                        Add +
                      </Button>
                    </div>
                  </FormControl>
                </Box>
              </FormControl>
            </>
          ) : openTask ? (
            <>
              <FormControl
                fullWidth
                sx={{
                  marginTop: 2,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Status}
                    label="Status"
                    variant="outlined"
                    error={orderStatusValid == false}
                    helperText={orderStatusValid == false && "Select Status"}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"assigned"}>Assigned</MenuItem>
                    <MenuItem value={"qc"}>QC</MenuItem>
                    <MenuItem value={"pass"}>Completed</MenuItem>
                    <MenuItem value={"fail"}>Failed</MenuItem>
                    <MenuItem value={"rework"}>Rework</MenuItem>
                  </Select>
                </FormControl>
                {(Status === "fail" || Status === "rework") && (
                  <FormControl fullWidth sx={{ marginTop: 3 }}>
                    <TextField
                      id="outlined-textarea"
                      label="Comment"
                      placeholder="Add Reason"
                      multiline
                    />
                  </FormControl>
                )}
              </FormControl>
            </>
          ) : openEditOrderDeadline ? (
            <Grid item xs={6}>
              <FormControl sx={{ m: 1 }} fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Order Deadline
                </FormLabel>
                <ButtonContainer
                  role="button"
                  onClick={() =>
                    setOpenEditOrderDeadlinePicker(!openEditOrderDeadlinePicker)
                  }
                  size="small"
                  ref={buttonRef}
                >
                  <span style={{ marginLeft: "10px" }}>
                    {orderDeadlineDate.format("DD-MM-YYYY")}
                  </span>
                  <IconButton
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      alignContent: "end",
                    }}
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                </ButtonContainer>

                {openEditOrderDeadlinePicker && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 99,
                      top:
                        buttonRef.current.offsetTop +
                        buttonRef.current.offsetHeight +
                        "px",
                      left: buttonRef.current.offsetLeft + "px",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        backgroundColor: "#fff",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        transform: "scale(0.9)",
                        transformOrigin: "top left",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          value={orderDeadlineDate}
                          onChange={(newValue) => {
                            setOrderDeadlineDate(newValue);
                            const dateObject = new Date(newValue.$d);
                            console.log("new Value dateObject", dateObject);
                            const formattedDate = dateObject
                              .toISOString()
                              .split("T")[0];
                            console.log("Formatted Date", formattedDate);
                            setSelectedDate(formattedDate);
                            setOpenEditOrderDeadlinePicker(false);
                          }}
                          minDate={dayjs()}
                          sx={{
                            "& .MuiCalendarPicker-root": {
                              transform: "scale(0.8)",
                              transformOrigin: "top left",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
              </FormControl>
            </Grid>
          ) : openEditExpertDeadline ? (
            <Grid item xs={6}>
              <FormControl sx={{ m: 1 }} fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Expert Deadline
                </FormLabel>
                <ButtonContainer
                  role="button"
                  onClick={() =>
                    setEditExpertDeadlinePicker(!openEditExpertDeadlinePicker)
                  }
                  size="small"
                  ref={buttonRef}
                >
                  <span style={{ marginLeft: "10px" }}>
                    {expertDeadlineDate.format("DD-MM-YYYY")}
                  </span>
                  <IconButton
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      alignContent: "end",
                    }}
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                </ButtonContainer>

                {openEditExpertDeadlinePicker && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 99,
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        top: 63,
                        left: 60,
                        backgroundColor: "#fff",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        transform: "scale(0.9)",
                        transformOrigin: "top left",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          value={expertDeadlineDate}
                          onChange={(newValue) => {
                            console.log("new value working");
                            setExpertDeadlineDate(newValue);
                            const dateObject = new Date(newValue.$d);
                            console.log("new Value dateObject", dateObject);
                            const formattedDate = dateObject
                              .toISOString()
                              .split("T")[0];
                            console.log("Formatted Date", formattedDate);
                            setSelectedDate(formattedDate);

                            setEditExpertDeadlinePicker(false);
                          }}
                          minDate={dayjs()}
                          sx={{
                            "& .MuiCalendarPicker-root": {
                              transform: "scale(0.8)",
                              transformOrigin: "top left",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
              </FormControl>
            </Grid>
          ) : openEditSubject ? (
            <FormControl sx={{ width: "250px", marginTop: "10px" }}>
              <TextField
                required
                id="demo1-simple-select"
                value={editSubject}
                label="Subject"
                onChange={(event) => setEditSubject(event.target.value)}
              />
            </FormControl>
          ) : isCommentModalOpen ? (
            <>
              <FormControl sx={{ width: "250px", marginTop: "10px" }}>
                <TextField
                  required
                  value={commentForOperation}
                  label="Comment"
                  onChange={(event) =>
                    setCommentForOperation(event.target.value)
                  }
                />
              </FormControl>
            </>
          ) : isAddMeetingScheduleModalOpen ? (
            <Grid item xs={6}>
              <FormControl sx={{ m: 1 }} fullWidth>
                <FormLabel>Meeting Deadline</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={meetingData?.deadline}
                    onChange={(value) => {
                      console.log("choosen value", value);
                      setmeetingData({ ...meetingData, deadline: value });
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ m: 1 }} fullWidth>
                <FormLabel>Meeting Time</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={dayjs(meetingData.meetingTime, "HH:mm")}
                    onChange={(value) => {
                      console.log("time value", value);
                      setmeetingData({ ...meetingData, meetingTime: value });
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ m: 1 }} fullWidth>
                <FormLabel>Comment</FormLabel>
                <TextField
                  required
                  value={meetingData.meetingComment}
                  onChange={(event) =>
                    setmeetingData({
                      ...meetingData,
                      meetingComment: event.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          ) : isHighPriorityModalOpen ? (
            <Grid item xs={6}>
              <FormControl sx={{ m: 1 }} fullWidth>
                <FormLabel>Deadine</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={priorityData.priorityDeadline}
                    onChange={(value) => {
                      setPriorityData({
                        ...priorityData,
                        priorityDeadline: value,
                      });
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ m: 1 }} fullWidth>
                <TextField
                  required
                  value={priorityData.priorityComment}
                  label="comment"
                  onChange={(event) =>
                    setPriorityData({
                      ...priorityData,
                      priorityComment: event.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          ) : isUrgentDeadlineModalOpen ? (
            <Grid item xs={6}>
              <FormControl sx={{ m: 1 }} fullWidth>
                <FormLabel>Deadine</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={urgentData.urgentDate}
                    onChange={(value) => {
                      setUrgentData({ ...urgentData, urgentDate: value });
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ m: 1 }} fullWidth>
                <TextField
                  required
                  value={urgentData.urgentComment}
                  label="Comment"
                  onChange={(event) =>
                    setUrgentData({
                      ...urgentData,
                      urgentComment: event.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          ) : (
            ""
          )
        }
      />
    </div>
  );
}

export default AssignTaskConsole;
