import React, { Component, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Table,
  TableContainer,
  TablePagination,
  TableHead,
  TableBody,
  Button,
  DialogActions,
  Paper,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "./styles/TableStyles";

import { useEffect, useRef } from "react";
import { FRONTEND_API } from "./urls";
import TextField from "@mui/material/TextField";
import {
  DateCalendar,
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { ButtonContainer } from "./styles/style";

import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MessageComponent from "./MessageComponent";
import CloseIcon from "@mui/icons-material/Close";
import { concat } from "lodash";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";

const DPRConsole = ({ open, handleClose, userId, getAllData, dprType }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [dprDetails, setDprDetails] = useState([]);
  const token = localStorage.getItem("token");
  const roles = localStorage.getItem("roles");

  const [editMode, setEditMode] = useState(false);
  const [editedDprDetails, setEditedDprDetails] = useState([]);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const buttonRef = useRef(null);
  const today = dayjs();

  const [severity, setSeverity] = useState(null);
  const [alertContent, setAlertContent] = useState("");

  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const rawData = await fetchDpr();
      if (rawData) {
        console.log("dpr get request ", rawData);
        setDprDetails(rawData);
        // Extract unique months from rawData
        const uniqueMonths = [
          ...new Set(
            rawData.map((dpr) =>
              new Date(
                roles === "lead" && dprType === "adminLeadDpr"
                  ? dpr.deadline
                  : roles === "lead" || roles === "expert"
                  ? dpr.assigned_expert_deadline
                  : roles === "admin" && dprType === "teamMemberDpr"
                  ? dpr.assigned_expert_deadline
                  : dpr.deadline
              ).toLocaleString("default", {
                month: "long",
              })
            )
          ),
        ];
        setMonths(uniqueMonths);
        // Set default selected month
        const currentMonth = new Date().toLocaleString("default", {
          month: "long",
        });

        // Set default selected month
        setSelectedMonth(
          uniqueMonths.includes(currentMonth) ? currentMonth : uniqueMonths[0]
        );
      }
    };
    fetchData();
  }, [setDprDetails]);

  const fetchDpr = async () => {
    try {
      var formdata = new FormData();
      formdata.append("userId", userId);
      console.log(userId);
      var requestOptions = {
        method: "GET",
        // body: formdata,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await fetch(
        FRONTEND_API + "getDpr/".concat(userId),
        requestOptions
      );
      const rawData = await response.json();
      console.log("get dpr raw", rawData);
      return rawData;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const handleDate = (expertDate) => {
    var formattedDate = null;
    if (expertDate != null) {
      const dateObject = new Date(expertDate);
      formattedDate = dateObject.toDateString();
    }
    return formattedDate;
  };

  // const [editDprDialog, setEditDprDialog] = useState(false);

  const saveDpr = () => {
    console.log("edited dpr details is here", editedDprDetails);
    const jsonData = JSON.stringify(editedDprDetails);

    var requestOptions = {
      method: "POST",
      body: jsonData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let status = "";
    fetch(FRONTEND_API + "editDpr", requestOptions)
      .then((response) => {
        if (response.status == 400) {
          status = "400";
          return response.json();
        } else if (response.status == 200) {
          status = "200";
          return response.json();
        }
      })

      .then((result) => {
        //JSON.parse(response._bodyText)
        if (status == "200") {
          setAlertContent(result.message);
          setEditMode(false);
          setDialogOpen(true);
          getAllData();
          setSeverity(true);
        } else {
          setAlertContent(result.message);
          setDialogOpen(true);
          setSeverity(false);
        }
      })
      .catch((rejected) => {
        console.log(rejected);
      });

    // Perform saving logic here, for example, updating the backend or state
    // After saving, you might want to close the dialog
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const [dateChangeIndex, setDateChangeIndex] = useState();
  const handleButtonClick = (index) => {
    console.log("index received", index);
    setDateChangeIndex(index);
    if (openDatePicker) {
      setOpenDatePicker(false);
    } else {
      setOpenDatePicker(true);
    }
  };

  const handleDeadlineChange = (newDeadline) => {
    console.log("new deadline", newDeadline);
    console.log(dateChangeIndex);
    console.log(editedDprDetails);
    setEditedDprDetails((prevState) => {
      // Create a new array with updated deadline for the specified index
      const updatedDprDetails = [...prevState];
      if (roles === "lead" && dprType === "adminLeadDpr") {
        updatedDprDetails[dateChangeIndex] = {
          ...updatedDprDetails[dateChangeIndex],
          deadline: newDeadline,
        };
      } else if (roles === "lead" || roles === "expert") {
        updatedDprDetails[dateChangeIndex] = {
          ...updatedDprDetails[dateChangeIndex],
          assigned_expert_deadline: newDeadline,
        };
      } else if (roles === "admin" && dprType === "teamMemberDpr") {
        updatedDprDetails[dateChangeIndex] = {
          ...updatedDprDetails[dateChangeIndex],
          assigned_expert_deadline: newDeadline,
        };
      } else {
        updatedDprDetails[dateChangeIndex] = {
          ...updatedDprDetails[dateChangeIndex],
          deadline: newDeadline,
        };
      }

      return updatedDprDetails;
    });
  };

  console.log("edited dpr details", editedDprDetails);

  const handleRemoveRow = (row) => {
    console.log(row);
    var formdata = new FormData();
    formdata.append("expert_id", userId);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    let status = "";
    fetch(FRONTEND_API + "removeAssignedOrder/" + concat(row), requestOptions)
      .then((response) => {
        if (response.status == 404) {
          status = "400";
          return response.json();
        } else if (response.status == 200) {
          status = "200";
          return response.json();
        }
      })
      .then((result) => {
        //JSON.parse(response._bodyText)
        if (status == "200") {
          fetchDpr()
            .then(() => {
              setDialogOpen(true);
              setSeverity(true);
            })
            .catch((error) => {
              console.error("Error fetching DPR:", error);
              setDialogOpen(true);
              setSeverity(false);
            });
        } else {
          setAlertContent(result.message);
          setDialogOpen(true);
          setSeverity(false);
        }
      })
      .catch((error) => {
        setAlertContent(error);
        setDialogOpen(true);
        setSeverity(false);
      });
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  // Filter dprDetails based on selectedMonth
  console.log("dprDetails", dprDetails);
  const filteredDprDetails = dprDetails.filter(
    (dpr) =>
      new Date(
        roles === "lead" && dprType === "adminLeadDpr"
          ? dpr.deadline
          : roles === "lead" || roles === "expert"
          ? dpr.assigned_expert_deadline
          : roles === "admin" && dprType === "teamMemberDpr"
          ? dpr.assigned_expert_deadline
          : dpr.deadline
      ).toLocaleString("default", { month: "long" }) === selectedMonth
  );

  const sortDprDetails = filteredDprDetails.sort((a, b) => {
    return (
      new Date(
        roles === "lead" && dprType === "adminLeadDpr"
          ? a.deadline
          : roles === "lead" || roles === "expert"
          ? a.assigned_expert_deadline
          : roles === "admin" && dprType === "teamMemberDpr"
          ? a.assigned_expert_deadline
          : a.deadline
      ) -
      new Date(
        roles === "lead" && dprType === "adminLeadDpr"
          ? b.deadline
          : roles === "lead" || roles === "expert"
          ? b.assigned_expert_deadline
          : roles === "admin" && dprType === "teamMemberDpr"
          ? b.assigned_expert_deadline
          : b.deadline
      )
    );
  });

  console.log("sorted dpr details", sortDprDetails);

  const editDpr = () => {
    setEditMode(true);
    console.log("edit details from dpr", filteredDprDetails);
    // Assuming dprDetails is your initial data array, you can copy it here for editing
    setEditedDprDetails([...filteredDprDetails]);
  };

  const CustomTableCell = styled(TableCell)({
    backgroundColor: "#00B050",
    color: "#fff",
    borderRight: "1px solid #fff",
    fontWeight: "bold",
  });

  console.log("edit dpr details outside", editedDprDetails);
  console.log("condition is here");
  return (
    <Box sx={{ width: "90%" }}>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            minWidth: 1100,
            // Add other custom styles here
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>DPR</DialogTitle>

        <DialogContent>
          <select value={selectedMonth} onChange={handleMonthChange}>
            {months.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
          <TableContainer
            component={Paper}
            sx={{
              marginBottom: 6,
            }}
            aria-label="customized table"
          >
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: "customGreenColor" }}>
                <StyledTableRow>
                  <CustomTableCell>Order ID</CustomTableCell>
                  {/* <CustomTableCell>Assigned On</CustomTableCell> */}
                  <CustomTableCell>Deadline</CustomTableCell>
                  <CustomTableCell>Words</CustomTableCell>
                  <CustomTableCell>Remarks</CustomTableCell>
                  <CustomTableCell>Incentive</CustomTableCell>
                  <CustomTableCell>Comment</CustomTableCell>
                  <CustomTableCell></CustomTableCell>
                </StyledTableRow>
              </TableHead>
              {editMode ? (
                <TableBody>
                  {editedDprDetails.map((dpr, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{dpr.order_id}</StyledTableCell>
                      {/*<StyledTableCell>{handleDate(dpr.assigned_date)}</StyledTableCell>*/}
                      <StyledTableCell>
                        <div>
                          <ButtonContainer
                            role="button"
                            onClick={() => handleButtonClick(index)}
                            size="small"
                            ref={buttonRef}
                          >
                            {roles === "lead" && dprType === "adminLeadDpr" ? (
                              <span style={{ marginLeft: "10px" }}>
                                {dpr.deadline}
                              </span>
                            ) : roles === "lead" || roles === "expert" ? (
                              <>
                                <span style={{ marginLeft: "10px" }}>
                                  {dpr.assigned_expert_deadline}
                                </span>
                              </>
                            ) : roles === "admin" &&
                              dprType === "teamMemberDpr" ? (
                              <span style={{ marginLeft: "10px" }}>
                                {dpr.assigned_expert_deadline}
                              </span>
                            ) : (
                              <span style={{ marginLeft: "10px" }}>
                                {dpr.deadline}
                              </span>
                            )}
                            {/* <span style={{ marginLeft: "10px" }}>
                              {dpr.assigned_expert_deadline}
                            </span> */}
                            <IconButton
                              sx={{
                                display: "flex",
                                alignItems: "end",
                                alignContent: "end",
                              }}
                            >
                              <CalendarTodayIcon />
                            </IconButton>
                          </ButtonContainer>
                          {openDatePicker && (
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 99,
                                top:
                                  buttonRef.current.offsetTop +
                                  buttonRef.current.offsetHeight +
                                  "px",
                                left: buttonRef.current.offsetLeft + "px",
                                backgroundColor: "#fff", // Set a background color for the calendar container
                                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                  value={dayjs(
                                    roles === "lead" &&
                                      dprType === "adminLeadDpr"
                                      ? dpr.deadline
                                      : roles === "lead" || roles === "expert"
                                      ? dpr.assigned_expert_deadline
                                      : roles === "admin" &&
                                        dprType === "teamMemberDpr"
                                      ? dpr.assigned_expert_deadline
                                      : dpr.deadline
                                  )}
                                  onChange={(e) => {
                                    handleDeadlineChange(
                                      dayjs(e).format(
                                        "ddd, DD MMM YYYY HH:mm:ss [GMT]"
                                      )
                                    );
                                    setOpenDatePicker(false);
                                  }}
                                  renderInput={() => null} // Hide the input inside the calendar
                                  PopperProps={{
                                    style: {
                                      zIndex: 99,
                                      backgroundColor: "#fff",
                                    }, // Adjust z-index as needed
                                  }}
                                  minDate={today}
                                />
                              </LocalizationProvider>
                            </div>
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          sx={{ width: 80 }}
                          value={dpr.no_of_words}
                          onChange={(e) => {
                            console.log(index);
                            const updatedDprDetails = [...editedDprDetails];
                            updatedDprDetails[index].no_of_words =
                              e.target.value;
                            setEditedDprDetails(updatedDprDetails);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          sx={{ width: 180 }}
                          value={dpr.remarks}
                          onChange={(e) => {
                            const updatedDprDetails = [...editedDprDetails];
                            updatedDprDetails[index].remarks = e.target.value;
                            setEditedDprDetails(updatedDprDetails);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          sx={{ width: 180 }}
                          value={dpr.incentive}
                          onChange={(e) => {
                            const updatedDprDetails = [...editedDprDetails];
                            updatedDprDetails[index].incentive = e.target.value;
                            setEditedDprDetails(updatedDprDetails);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          sx={{ width: 180 }}
                          value={dpr.comments}
                          onChange={(e) => {
                            const updatedDprDetails = [...editedDprDetails];
                            updatedDprDetails[index].comments = e.target.value;
                            setEditedDprDetails(updatedDprDetails);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  {console.log("filtered data", filteredDprDetails)}
                  {sortDprDetails.map((dpr, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{dpr.order_id}</StyledTableCell>
                      {/* <StyledTableCell>{handleDate(dpr.assigned_date)}</StyledTableCell> */}
                      <StyledTableCell>
                        {roles === "lead" && dprType === "adminLeadDpr"
                          ? handleDate(dpr.deadline)
                          : roles === "lead" || dprType === "expert"
                          ? handleDate(dpr.assigned_expert_deadline)
                          : roles === "admin" && dprType === "teamMemberDpr"
                          ? handleDate(dpr.assigned_expert_deadline)
                          : handleDate(dpr.deadline)}
                        {console.log(
                          "dpr is here",
                          dpr.assigned_expert_deadline
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{dpr.no_of_words}</StyledTableCell>
                      <StyledTableCell>{dpr.remarks}</StyledTableCell>
                      <StyledTableCell>{dpr.incentive}</StyledTableCell>
                      <StyledTableCell>{dpr.comments}</StyledTableCell>

                      <StyledTableCell>
                        <CloseIcon onClick={() => handleRemoveRow(dpr.id)} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          {(roles === "lead" && dprType === "adminLeadDpr") ||
          roles === "expert" ? (
            <></>
          ) : editMode ? (
            <Button onClick={saveDpr}>Save</Button>
          ) : (
            <Button sx={{ color: "#00B050" }} onClick={editDpr}>
              Edit
            </Button>
          )}
          <Button sx={{ color: "#00B050" }} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {dialogOpen && (
        <MessageComponent
          message={alertContent}
          severity={severity}
          close={handleCloseDialog}
        />
      )}
    </Box>
  );
};

export default DPRConsole;
